import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import axios, { Method } from 'axios';
import fileDownload from 'js-file-download';
import { FC, useState } from 'react';
import { downloadCampaignBookingConfirmation } from '../../utilities/api';

type CampaignBookingConfirmationButtonProps = {
  campaignId: string;
};

const CampaignBookingConfirmationButton: FC<CampaignBookingConfirmationButtonProps> =
  ({ campaignId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const download = () => {
      const myFetch = async (
        input: RequestInfo,
        init?: RequestInit
      ): Promise<Response> => {
        const response = await axios(input as string, {
          method: init?.method as Method,
          headers: init?.headers,
          responseType: 'blob',
        });

        // we highjack the download here and save the file directly
        if (response.status === 200) {
          fileDownload(response.data, 'Buchungsbestätigung.pdf');
        }

        return new Response(response.data, {
          headers: response.headers,
          status: response.status,
          statusText: response.statusText,
        });
      };

      setIsLoading(true);
      // remember we save the pdf in the fetching method like savages
      downloadCampaignBookingConfirmation(campaignId, {
        fetch: myFetch,
      }).finally(() => setIsLoading(false));
    };

    return (
      <Button
        type="primary"
        icon={<SaveOutlined />}
        onClick={download}
        loading={isLoading}
      >
        Buchungsbestätigung
      </Button>
    );
  };

export default CampaignBookingConfirmationButton;
