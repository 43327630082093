import { FC } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Layout } from 'antd';

import './AuthLayout.css';

const AuthLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  return (
    <Layout className="AuthLayout">
      <Layout.Content className="content">
        {renderRoutes(route?.routes)}
      </Layout.Content>
      <Layout.Footer className="footer">InstoreTV 2.0</Layout.Footer>
    </Layout>
  );
};

export default AuthLayout;
