import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeCampaign, Campaign } from '../../utilities/api';

type CampaignDeleteButtonProps = {
  campaign: Campaign;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const CampaignDeleteButton: FC<CampaignDeleteButtonProps> = ({
  campaign,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${campaign.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeCampaign(campaign.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default CampaignDeleteButton;
