import { FC } from 'react';
import { useHistory } from 'react-router';
import SlotOverview from '../../components/slot/SlotOverview';

const SlotOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <SlotOverview selectSlot={(slotId) => history.push(`/slot/${slotId}`)} />
  );
};

export default SlotOverviewPage;
