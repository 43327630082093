import moment from 'moment';
import { FC } from 'react';

type TimeStampProps = {
  timestamp?: string;
};

const TimeStamp: FC<TimeStampProps> = ({ timestamp }) => {
  if (!timestamp) {
    return <></>;
  }
  return <>{moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}</>;
};

export default TimeStamp;
