import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import VideoSingle from '../../components/video/VideoSingle';

type VideoSinglePageProps = {
  videoId: string;
};

const VideoSinglePage: FC<RouteConfigComponentProps<VideoSinglePageProps>> = ({
  match,
}) => {
  const { videoId } = match.params;

  return <VideoSingle videoId={videoId} />;
};

export default VideoSinglePage;
