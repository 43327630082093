import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  InputNumber,
  Typography,
} from 'antd';
import moment from 'moment';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  getCampaign,
  updateCampaign,
  CampaignToVideo,
} from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import StoreGroupMultiSelect from '../store-group/StoreGroupMultiSelect';
import StoreMultiSelect from '../store/StoreMultiSelect';
import VideoSelect from '../video/VideoSelect';
import CampaignBookingConfirmationButton from './CampaignBookingConfirmationButton';
import CampaignMultiSelect from './CampaignMultiSelect';

type CampaignSingleProps = {
  campaignId: string;
};

const CampaignSingle: FC<CampaignSingleProps> = ({ campaignId }) => {
  const queryClient = useQueryClient();

  const { data: campaign } = useQuery(['/campaign', { id: campaignId }], () =>
    getCampaign(campaignId).then((res) =>
      res.status === 200 ? res.data : undefined
    )
  );

  const [addVideoDropdownVisible, setAddVideoDropdownVisible] = useState(false);

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/campaign');
    }
  };

  const editName = (name: string) =>
    updateCampaign(campaignId, { name }).then(invalidateOnSuccess);

  const editDescription = (description: string) =>
    updateCampaign(campaignId, { description }).then(invalidateOnSuccess);

  const editValidityStart = (validityStart?: moment.Moment | null) => {
    if (validityStart) {
      updateCampaign(campaignId, {
        validity_start: validityStart.toISOString(true).slice(0, 10),
      }).then(invalidateOnSuccess);
    }
  };

  const editValidityEnd = (validityEnd?: moment.Moment | null) => {
    if (validityEnd) {
      updateCampaign(campaignId, {
        validity_end: validityEnd.toISOString(true).slice(0, 10),
      }).then(invalidateOnSuccess);
    }
  };

  const editFrequency = (frequency: number) =>
    updateCampaign(campaignId, { frequency }).then(invalidateOnSuccess);

  const addVideo = (videoId: string) => {
    const newVideo: CampaignToVideo = {
      video: { id: videoId },
    };
    const newVideos = (campaign?.videos || []).concat([newVideo]);
    updateCampaign(campaignId, { videos: newVideos }).then(invalidateOnSuccess);
  };

  const removeVideo = (index: number) => {
    const newVideos = campaign?.videos.filter(
      (_, videoIndex) => index !== videoIndex
    );
    updateCampaign(campaignId, { videos: newVideos }).then(invalidateOnSuccess);
  };

  const editVideoVideo = (index: number, videoId: string) => {
    const newVideos =
      campaign?.videos.map((video, videoIndex) => {
        if (index === videoIndex) {
          return {
            ...video,
            video: { id: videoId },
          };
        }
        return video;
      }) || [];
    updateCampaign(campaignId, { videos: newVideos }).then(invalidateOnSuccess);
  };

  const editVideoValidityStart = (
    index: number,
    validityStart?: moment.Moment | null
  ) => {
    const newVideos =
      campaign?.videos.map((video, videoIndex) => {
        if (index === videoIndex) {
          return {
            ...video,
            validity_start:
              validityStart?.toISOString(true).slice(0, 10) || null,
          };
        }
        return video;
      }) || [];
    updateCampaign(campaignId, { videos: newVideos }).then(invalidateOnSuccess);
  };

  const editVideoValidityEnd = (
    index: number,
    validityEnd?: moment.Moment | null
  ) => {
    const newVideos =
      campaign?.videos.map((video, videoIndex) => {
        if (index === videoIndex) {
          return {
            ...video,
            validity_end: validityEnd?.toISOString(true).slice(0, 10) || null,
          };
        }
        return video;
      }) || [];
    updateCampaign(campaignId, { videos: newVideos }).then(invalidateOnSuccess);
  };

  const editStores = (storeIds: string[]) =>
    updateCampaign(campaignId, { stores: storeIds.map((id) => ({ id })) }).then(
      invalidateOnSuccess
    );

  const editStoreGroups = (storeGroupIds: string[]) =>
    updateCampaign(campaignId, {
      store_groups: storeGroupIds.map((id) => ({ id })),
    }).then(invalidateOnSuccess);

  const editSimilar = (similarIds: string[]) =>
    updateCampaign(campaignId, {
      similar: similarIds.map((id) => ({ id })),
    }).then(invalidateOnSuccess);

  return (
    <Card
      title={
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>{campaign?.name}</div>
          <CampaignBookingConfirmationButton campaignId={campaignId} />
        </div>
      }
    >
      <EntityFieldRow
        label="Name"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editName }}
          >
            {campaign?.name || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Beschreibung"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editDescription }}
          >
            {campaign?.description || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Gültigkeit Start"
        field={
          <DatePicker
            value={
              campaign?.validity_start
                ? moment(campaign?.validity_start)
                : undefined
            }
            onChange={editValidityStart}
            allowClear={false}
            placeholder="Startdatum"
          />
        }
      />
      <EntityFieldRow
        label="Gültigkeit Ende"
        field={
          <DatePicker
            value={
              campaign?.validity_end
                ? moment(campaign?.validity_end)
                : undefined
            }
            onChange={editValidityEnd}
            allowClear={false}
            placeholder="Enddatum"
          />
        }
      />
      <EntityFieldRow
        label="Frequenz"
        field={
          <InputNumber
            value={campaign?.frequency}
            onChange={(value) => editFrequency(Number(value))}
            min={0}
          />
        }
      />
      <EntityFieldRow
        label="Videos"
        field={
          <>
            {campaign?.videos.map(
              ({ video, validity_start, validity_end }, index) => (
                <div key={[video.id, index].join()}>
                  <VideoSelect
                    value={video.id}
                    onChange={(videoId) =>
                      videoId && editVideoVideo(index, videoId)
                    }
                  />
                  <DatePicker
                    value={validity_start ? moment(validity_start) : undefined}
                    onChange={(validityStart) =>
                      editVideoValidityStart(index, validityStart)
                    }
                    placeholder="Startdatum"
                    style={{ marginTop: 16, marginRight: 16 }}
                  />
                  <DatePicker
                    value={validity_end ? moment(validity_end) : undefined}
                    onChange={(validityEnd) =>
                      editVideoValidityEnd(index, validityEnd)
                    }
                    placeholder="Enddatum"
                    style={{ marginTop: 16, marginRight: 16 }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    style={{ marginTop: 16, float: 'right' }}
                    onClick={() => removeVideo(index)}
                  />
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                </div>
              )
            )}
            <Dropdown
              overlay={
                <Card style={{ padding: 8, minWidth: 400 }}>
                  <VideoSelect
                    onChange={(videoId) => {
                      setAddVideoDropdownVisible(false);
                      videoId && addVideo(videoId);
                    }}
                  />
                </Card>
              }
              placement="bottomLeft"
              visible={addVideoDropdownVisible}
            >
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() =>
                  setAddVideoDropdownVisible(!addVideoDropdownVisible)
                }
              >
                Video hinzufügen
              </Button>
            </Dropdown>
          </>
        }
      />
      <EntityFieldRow
        label="Stores"
        field={
          <StoreMultiSelect
            values={campaign?.stores.map(({ id }) => id)}
            onChange={editStores}
            filter={{ hasVideo: true }}
          />
        }
      />
      <EntityFieldRow
        label="Store Gruppen"
        field={
          <StoreGroupMultiSelect
            values={campaign?.store_groups.map(({ id }) => id)}
            onChange={editStoreGroups}
          />
        }
      />
      <EntityFieldRow
        label="Ähnliche Kampagnen"
        field={
          <CampaignMultiSelect
            values={campaign?.similar.map(({ id }) => id)}
            onChange={editSimilar}
          />
        }
      />
    </Card>
  );
};

export default CampaignSingle;
