import { Card, Col, Collapse, DatePicker, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import EntityFieldRow from '../common/EntityFieldRow';
import DmmeSelect from '../dmme/DmmeSelect';
import StoreSelect from '../store/StoreSelect';
import VideoSelect from '../video/VideoSelect';
import { getPlayReports, DatePlays } from '../../utilities/api';
import PlaylistSelect from '../playlist/PlaylistSelect';
import ReportChart from './ReportChart';

const Report: FC = () => {
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const startDateString = startDate.toISOString(true).slice(0, 10);

  const [endDate, setEndDate] = useState(moment());
  const endDateString = endDate.toISOString(true).slice(0, 10);

  const [videoId, setVideoId] = useState<string>();
  const [storeId, setStoreId] = useState<string>();
  const [dmmeId, setDmmeId] = useState<string>();
  const [playlistId, setPlaylistId] = useState<string>();

  const { data: playReports, isLoading } = useQuery(
    [
      '/report/plays',
      {
        startDate: startDateString,
        endDate: endDateString,
        videoId,
        storeId,
        dmmeId,
        playlistId,
      },
    ],
    () =>
      getPlayReports(startDateString, endDateString, {
        videoId,
        storeId,
        dmmeId,
        playlistId,
      }).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<DatePlays> = [
    {
      title: 'Datum',
      dataIndex: 'date',
    },
    {
      title: 'Plays',
      dataIndex: 'plays',
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card bodyStyle={{ padding: 0 }}>
          <Collapse defaultActiveKey={['default']} ghost>
            <Collapse.Panel header="Filter" key="default">
              <EntityFieldRow
                label="Startdatum"
                field={
                  <DatePicker
                    value={startDate}
                    onChange={(value) => value && setStartDate(value)}
                    allowClear={false}
                  />
                }
              />
              <EntityFieldRow
                label="Enddatum"
                field={
                  <DatePicker
                    value={endDate}
                    onChange={(value) => value && setEndDate(value)}
                    allowClear={false}
                  />
                }
              />
              <EntityFieldRow
                label="Video"
                field={
                  <VideoSelect
                    value={videoId}
                    onChange={setVideoId}
                    allowClear
                  />
                }
              />
              <EntityFieldRow
                label="Store"
                field={
                  <StoreSelect
                    value={storeId}
                    onChange={setStoreId}
                    allowClear
                  />
                }
              />
              <EntityFieldRow
                label="DMME"
                field={
                  <DmmeSelect value={dmmeId} onChange={setDmmeId} allowClear />
                }
              />
              <EntityFieldRow
                label="Playlist"
                field={
                  <PlaylistSelect
                    value={playlistId}
                    onChange={setPlaylistId}
                    allowClear
                  />
                }
              />
            </Collapse.Panel>
          </Collapse>
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <ReportChart
            data={playReports?.daily_plays.map(({ date, plays }) => ({
              date,
              value: plays,
            }))}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={playReports?.daily_plays}
          rowKey="date"
          loading={isLoading}
          size="small"
          pagination={{ pageSize: 1000 }}
        />
      </Col>
    </Row>
  );
};

export default Report;
