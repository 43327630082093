import { Button, Empty, Input, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getStoreGroups } from '../../utilities/api';

type StoreGroupMultiSelectProps = {
  values?: string[];
  onChange?: (storeGroupIds: string[]) => void;
};

const StoreGroupMultiSelect: FC<StoreGroupMultiSelectProps> = ({
  values,
  onChange,
}) => {
  const { data: storeGroups, isLoading } = useQuery(['/store-group'], () =>
    getStoreGroups().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const options = storeGroups?.map((storeGroup) => ({
    value: storeGroup.id,
    label: storeGroup.name,
  }));

  return (
    <Input.Group style={{ width: '100%', display: 'flex' }} compact>
      <Select
        mode="multiple"
        style={{ flexGrow: 1 }}
        placeholder="Store Gruppen auswählen"
        value={values}
        onChange={onChange}
        options={options}
        filterOption={(input, option) =>
          option?.label
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
        }
        notFoundContent={
          isLoading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
      />
      <Button>{values?.length || 0} ausgewählt</Button>
    </Input.Group>
  );
};

export default StoreGroupMultiSelect;
