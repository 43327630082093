import { Button, Checkbox } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getStores, Store } from '../../utilities/api';
import getBooleanSearch from '../table-search/boolean-search';
import getStringSearch from '../table-search/string-search';

type StoreOverviewProps = {
  selectStore?: (storeId: string) => void;
};

const StoreOverview: FC<StoreOverviewProps> = ({ selectStore }) => {
  const [page, setPage] = useState(0);

  const { data: stores, isLoading } = useQuery(['/store'], () =>
    getStores().then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
  );

  const columns: ColumnsType<Store> = [
    {
      title: 'Store Nummer',
      dataIndex: 'store_number',
      ellipsis: true,
      ...getStringSearch<Store>('store_number', 'Store Nummer'),
      sorter: (a, b) =>
        a.store_number
          ?.toLowerCase()
          .localeCompare(b.store_number?.toLowerCase() || '') || -1,
    },
    {
      title: 'Firma',
      dataIndex: 'company',
      ellipsis: true,
      ...getStringSearch<Store>('company', 'Firma'),
      sorter: (a, b) =>
        a.company
          ?.toLowerCase()
          .localeCompare(b.company?.toLowerCase() || '') || -1,
    },
    {
      title: 'PLZ',
      dataIndex: 'zip_code',
      ellipsis: true,
      ...getStringSearch<Store>('zip_code', 'PLZ'),
      sorter: (a, b) =>
        a.zip_code
          ?.toLowerCase()
          .localeCompare(b.zip_code?.toLowerCase() || '') || -1,
    },
    {
      title: 'Stadt',
      dataIndex: 'city',
      ellipsis: true,
      ...getStringSearch<Store>('city', 'Stadt'),
      sorter: (a, b) =>
        a.city?.toLowerCase().localeCompare(b.city?.toLowerCase() || '') || -1,
    },
    {
      title: 'Adresse',
      dataIndex: 'address',
      ellipsis: true,
      ...getStringSearch<Store>('address', 'Adresse'),
    },
    {
      title: 'Anz. DMMEs',
      dataIndex: 'dmmes',
      render: (dmmes: any[]) => dmmes.length,
      sorter: (a, b) => a.dmmes.length - b.dmmes.length,
    },
    {
      title: 'Anz. Store Gruppen',
      dataIndex: 'store_groups',
      render: (storeGroups: any[]) => storeGroups.length,
      sorter: (a, b) => a.store_groups.length - b.store_groups.length,
    },
    {
      title: 'Radio vorhanden',
      dataIndex: 'has_audio',
      ...getBooleanSearch<Store>('has_audio'),
      render: (hasAudio: boolean) => <Checkbox checked={hasAudio} />,
    },
    {
      title: 'Video vorhanden',
      dataIndex: 'has_video',
      ...getBooleanSearch<Store>('has_video'),
      render: (hasVideo: boolean) => <Checkbox checked={hasVideo} />,
    },
    {
      key: 'actions',
      render: (_, store) => (
        <>
          <Button
            type="link"
            onClick={() => selectStore && selectStore(store.id)}
          >
            Öffnen
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={stores}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default StoreOverview;
