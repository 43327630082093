import { FC } from 'react';
import { useHistory } from 'react-router';
import VideoGenreOverview from '../../components/video-genre/VideoGenreOverview';

const VideoGenreOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <VideoGenreOverview
      selectVideoGenre={(videoGenreId) =>
        history.push(`/video-genre/${videoGenreId}`)
      }
    />
  );
};

export default VideoGenreOverviewPage;
