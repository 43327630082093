import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Progress,
  Upload,
} from 'antd';
import { FC, useState } from 'react';
import { updateVideo, uploadVideoFile, Video } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';
import { useQueryClient } from 'react-query';
import axios, { Method } from 'axios';
import { RcFile } from 'antd/lib/upload';
import VideoGenreSelect from '../video-genre/VideoGenreSelect';
import moment from 'moment';

type VideoCreateFormFields = {
  name: string;
  supplier?: string;
  validityStart?: moment.Moment;
  validityEnd?: moment.Moment;
  genreId?: string;
  video: { file: RcFile };
};

type UploadOptions = {
  file: RcFile;
  onProgress?: (percent: number) => void;
  onError?: (err: any) => void;
  onSuccess?: (body: Video) => void;
};

const VideoCreateButton: FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const upload = async ({
    file,
    onProgress,
    onError,
    onSuccess,
  }: UploadOptions) => {
    const myFetch = async (
      input: RequestInfo,
      init?: RequestInit
    ): Promise<Response> => {
      const response = await axios(input as string, {
        method: init?.method as Method,
        headers: init?.headers,
        data: init?.body,
        onUploadProgress: (e) =>
          onProgress && onProgress((e.loaded / e.total) * 100),
        transformResponse: (res) => res,
      }).catch((err) => {
        onError && onError(err);
        throw err;
      });

      onSuccess && onSuccess(JSON.parse(response.data));

      return new Response(response.data, {
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
      });
    };

    await uploadVideoFile({ file: file as Blob }, { fetch: myFetch })
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/video');
        }
        return res;
      })
      .then(showMessageOnError);
  };

  const fileValidator = (_: any, value?: { fileList: RcFile[] }) => {
    if (value && value.fileList.length !== 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Keine Videodatei!'));
  };

  const submitForm = (formFields: VideoCreateFormFields) => {
    const { name, supplier, validityStart, validityEnd, genreId, video } =
      formFields;

    formRef.resetFields();
    setModalVisible(false);

    const notificationKey = String(Date.now());
    notification.open({
      key: notificationKey,
      message: `Upload: ${name}`,
      description: <Progress width={3} />,
      duration: null,
    });
    upload({
      file: video.file,
      onProgress: (percent) =>
        notification.open({
          key: notificationKey,
          message: `Upload: ${name}`,
          description: (
            <Progress
              strokeWidth={3}
              percent={Math.round(percent)}
              status="active"
            />
          ),
          duration: null,
        }),
      onError: () =>
        notification.open({
          key: notificationKey,
          message: `Upload: ${name}`,
          description: (
            <Progress strokeWidth={3} percent={100} status="exception" />
          ),
          duration: null,
        }),
      onSuccess: (body) => {
        notification.close(notificationKey);
        updateVideo(body.id, {
          name,
          supplier,
          ...(validityStart
            ? { validity_start: validityStart.toISOString(true).slice(0, 10) }
            : {}),
          ...(validityEnd
            ? { validity_end: validityEnd.toISOString(true).slice(0, 10) }
            : {}),
          ...(genreId ? { genre: { id: genreId } } : {}),
        }).then((res) => {
          if (res.status === 200) {
            queryClient.invalidateQueries('/video');
          }
        });
      },
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Video erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Video erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="supplier" label="Zulieferer">
            <Input />
          </Form.Item>
          <Form.Item name="validityStart" label="Gültigkeit Start">
            <DatePicker />
          </Form.Item>
          <Form.Item name="validityEnd" label="Gültigkeit Ende">
            <DatePicker />
          </Form.Item>
          <Form.Item name="genreId" label="Video Genre">
            <VideoGenreSelect />
          </Form.Item>
          <Form.Item
            name="video"
            label="Video"
            rules={[{ required: true }, { validator: fileValidator }]}
            valuePropName="file"
          >
            <Upload.Dragger
              accept="video/mp4"
              maxCount={1}
              beforeUpload={() => false}
            >
              <UploadOutlined style={{ fontSize: 36 }} />
              <br />
              Video hochladen
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VideoCreateButton;
