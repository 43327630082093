import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getSlots, Slot } from '../../utilities/api';
import Time from '../common/Time';
import getStringSearch from '../table-search/string-search';
import VideoTag from '../video/VideoTag';
import SlotCreateButton from './SlotCreateButton';
import SlotDeleteButton from './SlotDeleteButton';

type SlotOverviewProps = {
  selectSlot?: (slotId: string) => void;
};

const SlotOverview: FC<SlotOverviewProps> = ({ selectSlot }) => {
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { data: slots, isLoading } = useQuery(['/slot'], () =>
    getSlots().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<Slot> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 300,
      ...getStringSearch<Slot>('name', 'Name'),
      sorter: (a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || -1,
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      ellipsis: true,
      width: 100,
      ...getStringSearch<Slot>('name', 'Name'),
      sorter: (a, b) =>
        a.description
          ?.toLowerCase()
          .localeCompare(b.description?.toLowerCase() || '') || -1,
    },
    {
      title: 'Dauer',
      dataIndex: 'duration',
      width: 100,
      render: (duration: number) => <Time milliseconds={duration} />,
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: 'Frequenz',
      dataIndex: 'frequency',
      width: 100,
      sorter: (a, b) => a.frequency - b.frequency,
    },
    {
      title: 'Video',
      dataIndex: 'video',
      width: 100,
      render: (_, slot) =>
        slot.video ? <VideoTag videoId={slot.video.id} /> : null,
    },
    {
      key: 'actions',
      width: 100,
      render: (_, slot) => (
        <>
          <Button type="link" onClick={() => selectSlot && selectSlot(slot.id)}>
            Öffnen
          </Button>
          <SlotDeleteButton
            slot={slot}
            buttonType="link"
            onDelete={() => queryClient.invalidateQueries('/slot')}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <SlotCreateButton selectSlot={selectSlot} />
      <Table
        columns={columns}
        dataSource={slots}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default SlotOverview;
