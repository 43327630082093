import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createSlot } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';
import VideoSelect from '../video/VideoSelect';

type SlotCreateButtonProps = {
  selectSlot?: (slotId: string) => void;
};

type SlotCreateFormFields = {
  name: string;
  description?: string;
  duration: number;
  frequency: number;
  videoId?: string;
};

const SlotCreateButton: FC<SlotCreateButtonProps> = ({ selectSlot }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const submitForm = (formFields: SlotCreateFormFields) => {
    createSlot({
      ...formFields,
      video: formFields.videoId ? { id: formFields.videoId } : undefined,
    })
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/slot');
          formRef.resetFields();
          setModalVisible(false);
          selectSlot && selectSlot(res.data.id);
        }
        return res;
      })
      .then(showMessageOnError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Slot erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Slot erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Beschreibung">
            <Input />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Dauer [ms]"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="frequency"
            label="Frequenz"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="videoId" label="Video">
            <VideoSelect />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SlotCreateButton;
