import { ColumnFilterItem } from 'antd/lib/table/interface';
import objectPath from 'object-path';

type BooleanSearchReturn<T> = {
  filters: ColumnFilterItem[];
  onFilter: (value: string | number | boolean, record: T) => boolean;
};

function getBooleanSearch<T extends object>(
  dataIndex: keyof T
): BooleanSearchReturn<T> {
  return {
    filters: [
      { text: 'Ja', value: true },
      { text: 'Nein', value: false },
    ],
    onFilter: (value, record) =>
      objectPath.get(record, dataIndex as string | number) === value,
  };
}

export default getBooleanSearch;
