import { Card, Typography } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getVideoGenre, updateVideoGenre } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';

type VideoGenreSingleProps = {
  videoGenreId: string;
};

const VideoGenreSingle: FC<VideoGenreSingleProps> = ({ videoGenreId }) => {
  const queryClient = useQueryClient();

  const { data: videoGenre } = useQuery(
    ['/video-genre', { id: videoGenreId }],
    () =>
      getVideoGenre(videoGenreId).then((res) =>
        res.status === 200 ? res.data : undefined
      )
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/video-genre');
    }
  };

  const editName = (name: string) =>
    updateVideoGenre(videoGenreId, { name }).then(invalidateOnSuccess);

  return (
    <Card title={videoGenre?.name}>
      <EntityFieldRow
        label="Name"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editName }}
          >
            {videoGenre?.name || ''}
          </Typography.Paragraph>
        }
      />
    </Card>
  );
};

export default VideoGenreSingle;
