import { Button, Empty, Input, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getCampaigns } from '../../utilities/api';

type CampaignMultiSelectProps = {
  values?: string[];
  onChange?: (campaignIds: string[]) => void;
};

const CampaignMultiSelect: FC<CampaignMultiSelectProps> = ({
  values,
  onChange,
}) => {
  const { data: campaigns, isLoading } = useQuery(['/campaign'], () =>
    getCampaigns().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const options = campaigns?.map((campaign) => ({
    value: campaign.id,
    label: campaign.name,
  }));

  return (
    <Input.Group style={{ width: '100%', display: 'flex' }} compact>
      <Select
        mode="multiple"
        style={{ flexGrow: 1 }}
        placeholder="Kampagnen auswählen"
        value={values}
        onChange={onChange}
        options={options}
        filterOption={(input, option) =>
          option?.label
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
        }
        notFoundContent={
          isLoading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
      />
      <Button>{values?.length || 0} ausgewählt</Button>
    </Input.Group>
  );
};

export default CampaignMultiSelect;
