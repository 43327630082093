import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getUser, updateUser } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';

type UserSingleProps = {
  userId: string;
};

const UserSingle: FC<UserSingleProps> = ({ userId }) => {
  const queryClient = useQueryClient();
  const [editPasswordForm] = Form.useForm();

  const { data: user } = useQuery(['/user', { id: userId }], () =>
    getUser(userId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/user');
    }
  };

  const editName = (name: string) =>
    updateUser(userId, { name }).then(invalidateOnSuccess);

  const editEmail = (email: string) =>
    updateUser(userId, { email }).then(invalidateOnSuccess);

  const editPassword = (password: string) =>
    updateUser(userId, { password }).then((res) => {
      if (res.status === 200) {
        editPasswordForm.resetFields();
        message.success('Passwort wurde geändert');
      }
    });

  return (
    <Card title={user?.name}>
      <EntityFieldRow
        label="Name"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editName }}
          >
            {user?.name || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="E-Mail"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editEmail }}
          >
            {user?.email || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Neues Passwort"
        field={
          <Form
            form={editPasswordForm}
            layout="inline"
            onFinish={({ password }) => editPassword(password)}
            validateMessages={{
              required: 'Passwort ist ein notwendiges Feld!',
            }}
          >
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                Passwort speichern
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Card>
  );
};

export default UserSingle;
