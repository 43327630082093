import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideo } from '../../utilities/api';
import ITVTag from '../common/ITVTag';

type VideoTagProps = {
  videoId: string;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const VideoTag: FC<VideoTagProps> = ({ videoId, color }) => {
  const { data: video } = useQuery(['/video', { id: videoId }], () =>
    getVideo(videoId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  return <ITVTag color={color}>{video?.name}</ITVTag>;
};

export default VideoTag;
