import { Empty, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getDmmes } from '../../utilities/api';

type DmmeSelectProps = {
  value?: string;
  onChange?: (dmmeId?: string) => void;
  allowClear?: boolean;
};

const DmmeSelect: FC<DmmeSelectProps> = ({
  value,
  onChange,
  allowClear = false,
}) => {
  const { data: dmmes, isLoading } = useQuery(['/dmmes'], () =>
    getDmmes().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const options = dmmes?.map((dmme) => ({
    value: dmme.id,
    label: dmme.serial_number,
  }));

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="DMME auswählen"
      value={value}
      onChange={onChange}
      options={options}
      showSearch
      filterOption={(input, option) =>
        option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
        false
      }
      allowClear={allowClear}
      notFoundContent={
        isLoading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  );
};

export default DmmeSelect;
