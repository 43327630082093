import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getVideoGenres, getVideos, Video } from '../../utilities/api';
import Time from '../common/Time';
import TimeStamp from '../common/TimeStamp';
import getDateSearch from '../table-search/date-search';
import getGenreSearch from '../table-search/genre-search';
import getStringSearch from '../table-search/string-search';
import VideoGenreTag from '../video-genre/VideoGenreTag';
import VideoCreateButton from './VideoCreateButton';
import VideoDeleteButton from './VideoDeleteButton';

type VideoOverviewProps = {
  selectVideo?: (videoId: string) => void;
};

const VideoOverview: FC<VideoOverviewProps> = ({ selectVideo }) => {
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { data: videos, isLoading } = useQuery(['/video'], () =>
    getVideos().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const { data: videoGenres } = useQuery(['/video-genre'], () =>
    getVideoGenres().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<Video> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 300,
      ...getStringSearch<Video>('name', 'Name'),
      sorter: (a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || -1,
    },
    {
      title: 'Zulieferer',
      dataIndex: 'supplier',
      ellipsis: true,
      width: 100,
      ...getStringSearch<Video>('supplier', 'Zulieferer'),
      sorter: (a, b) =>
        a.supplier
          ?.toLowerCase()
          .localeCompare(b.supplier?.toLowerCase() || '') || -1,
    },
    {
      title: 'Gültigkeit Start',
      dataIndex: 'validity_start',
      width: 100,
      ...getDateSearch<Video>('validity_start', 'Gültigkeit Start'),
      sorter: (a, b) =>
        a.validity_start
          ?.toLowerCase()
          .localeCompare(b.validity_start?.toLowerCase() || '') || -1,
    },
    {
      title: 'Gültigkeit Ende',
      dataIndex: 'validity_end',
      width: 100,
      ...getDateSearch<Video>('validity_end', 'Gültigkeit Ende'),
      sorter: (a, b) =>
        a.validity_end
          ?.toLowerCase()
          .localeCompare(b.validity_end?.toLowerCase() || '') || -1,
    },
    {
      title: 'Dauer',
      dataIndex: 'duration',
      width: 100,
      render: (duration: number) => <Time milliseconds={duration} />,
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: 'Video Genre',
      dataIndex: 'genre',
      width: 100,
      render: (_, video) =>
        video.genre ? <VideoGenreTag videoGenreId={video.genre.id} /> : null,
      ...getGenreSearch<Video>('genre', videoGenres || []),
    },
    {
      title: 'Erstellung',
      dataIndex: 'created_at',
      width: 100,
      render: (_, video) => <TimeStamp timestamp={video.created_at} />,
      sorter: (a, b) =>
        a.created_at
          ?.toLowerCase()
          .localeCompare(b.created_at?.toLowerCase() || '') || -1,
    },
    {
      key: 'actions',
      width: 100,
      render: (_, video) => (
        <>
          <Button
            type="link"
            onClick={() => selectVideo && selectVideo(video.id)}
          >
            Öffnen
          </Button>
          <VideoDeleteButton
            video={video}
            buttonType="link"
            onDelete={() => queryClient.invalidateQueries('/video')}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <VideoCreateButton />
      <Table
        columns={columns}
        dataSource={videos}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default VideoOverview;
