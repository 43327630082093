import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createUser } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';

type UserCreateButtonProps = {
  selectUser?: (userId: string) => void;
};

type UserCreateFormFields = {
  name: string;
  email: string;
  password: string;
};

const UserCreateButton: FC<UserCreateButtonProps> = ({ selectUser }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const submitForm = (formFields: UserCreateFormFields) => {
    createUser(formFields)
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/user');
          formRef.resetFields();
          setModalVisible(false);
          selectUser && selectUser(res.data.id);
        }
        return res;
      })
      .then(showMessageOnError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Benutzer erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Benutzer erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Passwort"
            rules={[{ required: true }]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserCreateButton;
