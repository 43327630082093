import { Button, Checkbox } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getStoreGroups, StoreGroup } from '../../utilities/api';
import getBooleanSearch from '../table-search/boolean-search';
import getStringSearch from '../table-search/string-search';
import StoreGroupCreateButton from './StoreGroupCreateButton';
import StoreGroupDeleteButton from './StoreGroupDeleteButton';

type StoreGroupOverviewProps = {
  selectStoreGroup?: (storeGroupId: string) => void;
};

const StoreGroupOverview: FC<StoreGroupOverviewProps> = ({
  selectStoreGroup,
}) => {
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { data: storeGroups, isLoading } = useQuery(['/store-group'], () =>
    getStoreGroups().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<StoreGroup> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 300,
      ...getStringSearch<StoreGroup>('name', 'Name'),
      sorter: (a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || -1,
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      width: 100,
      ...getStringSearch<StoreGroup>('name', 'Name'),
      ellipsis: true,
      sorter: (a, b) =>
        a.description
          ?.toLowerCase()
          .localeCompare(b.description?.toLowerCase() || '') || -1,
    },
    {
      title: 'Standard',
      dataIndex: 'default',
      width: 100,
      ...getBooleanSearch<StoreGroup>('default'),
      render: (isDefault: boolean) => <Checkbox checked={isDefault} />,
    },
    {
      title: 'Anz. Stores',
      dataIndex: 'stores',
      width: 100,
      render: (stores: any[]) => stores.length,
      sorter: (a, b) => a.stores.length - b.stores.length,
    },
    {
      key: 'actions',
      width: 100,
      render: (_, storeGroup) => (
        <>
          <Button
            type="link"
            onClick={() => selectStoreGroup && selectStoreGroup(storeGroup.id)}
          >
            Öffnen
          </Button>
          <StoreGroupDeleteButton
            storeGroup={storeGroup}
            buttonType="link"
            onDelete={() => queryClient.invalidateQueries('/store-group')}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <StoreGroupCreateButton selectStoreGroup={selectStoreGroup} />
      <Table
        columns={columns}
        dataSource={storeGroups}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default StoreGroupOverview;
