import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import StoreSingle from '../../components/store/StoreSingle';

type StoreSinglePageProps = {
  storeId: string;
};

const StoreSinglePage: FC<RouteConfigComponentProps<StoreSinglePageProps>> = ({
  match,
}) => {
  const { storeId } = match.params;

  return <StoreSingle storeId={storeId} />;
};

export default StoreSinglePage;
