import { Card, Switch, Typography } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getStoreGroup, updateStoreGroup } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import StoreMultiSelect from '../store/StoreMultiSelect';

type StoreGroupSingleProps = {
  storeGroupId: string;
};

const StoreGroupSingle: FC<StoreGroupSingleProps> = ({ storeGroupId }) => {
  const queryClient = useQueryClient();

  const { data: storeGroup } = useQuery(
    ['/store-group', { id: storeGroupId }],
    () =>
      getStoreGroup(storeGroupId).then((res) =>
        res.status === 200 ? res.data : undefined
      )
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/store-group');
    }
  };

  const editName = (name: string) =>
    updateStoreGroup(storeGroupId, { name }).then(invalidateOnSuccess);

  const editDescription = (description: string) =>
    updateStoreGroup(storeGroupId, { description }).then(invalidateOnSuccess);

  const editDefault = (isDefault: boolean) =>
    updateStoreGroup(storeGroupId, { default: isDefault }).then(
      invalidateOnSuccess
    );

  const editStores = (storeIds: string[]) =>
    updateStoreGroup(storeGroupId, {
      stores: storeIds.map((id) => ({ id })),
    }).then(invalidateOnSuccess);

  return (
    <Card title={storeGroup?.name}>
      <EntityFieldRow
        label="Name"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editName }}
          >
            {storeGroup?.name || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Beschreibung"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editDescription }}
          >
            {storeGroup?.description || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Standard"
        field={<Switch checked={storeGroup?.default} onChange={editDefault} />}
      />
      <EntityFieldRow
        label="Stores"
        field={
          <StoreMultiSelect
            values={storeGroup?.stores.map((store) => store.id)}
            onChange={editStores}
          />
        }
      />
    </Card>
  );
};

export default StoreGroupSingle;
