import { ColumnFilterItem } from 'antd/lib/table/interface';
import objectPath from 'object-path';
import { VideoGenre } from '../../utilities/api';
import VideoGenreTag from '../video-genre/VideoGenreTag';

type GenreSearchReturn<T> = {
  filters: ColumnFilterItem[];
  onFilter: (value: string | number | boolean, record: T) => boolean;
};

function getGenreSearch<T extends object>(
  dataIndex: string,
  videoGenres: VideoGenre[]
): GenreSearchReturn<T> {
  return {
    filters: videoGenres.map(({ id }) => ({
      text: <VideoGenreTag videoGenreId={id} />,
      value: id,
    })),
    onFilter: (value, record) => {
      const genre = objectPath.get(record, dataIndex) as VideoGenre | undefined;
      return genre?.id === value;
    },
  };
}

export default getGenreSearch;
