import { Button, Checkbox } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getCampaigns, Campaign } from '../../utilities/api';
import getBooleanSearch from '../table-search/boolean-search';
import getDateSearch from '../table-search/date-search';
import getStringSearch from '../table-search/string-search';
import CampaignCreateButton from './CampaignCreateButton';
import CampaignDeleteButton from './CampaignDeleteButton';

type CampaignOverviewProps = {
  selectCampaign?: (campaignId: string) => void;
};

const CampaignOverview: FC<CampaignOverviewProps> = ({ selectCampaign }) => {
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { data: campaigns, isLoading } = useQuery(['/campaign'], () =>
    getCampaigns().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<Campaign> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 300,
      ...getStringSearch<Campaign>('name', 'Name'),
      sorter: (a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || -1,
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      ellipsis: true,
      width: 100,
      ...getStringSearch<Campaign>('description', 'Beschreibung'),
      sorter: (a, b) =>
        a.description
          ?.toLowerCase()
          .localeCompare(b.description?.toLowerCase() || '') || -1,
    },
    {
      title: 'Gültigkeit Start',
      dataIndex: 'validity_start',
      width: 100,
      ...getDateSearch<Campaign>('validity_start', 'Gültigkeit Start'),
      sorter: (a, b) =>
        a.validity_start
          ?.toLowerCase()
          .localeCompare(b.validity_start?.toLowerCase() || '') || -1,
    },
    {
      title: 'Gültigkeit Ende',
      dataIndex: 'validity_end',
      width: 100,
      ...getDateSearch<Campaign>('validity_end', 'Gültigkeit Ende'),
      sorter: (a, b) =>
        a.validity_end
          ?.toLowerCase()
          .localeCompare(b.validity_end?.toLowerCase() || '') || -1,
    },
    {
      title: 'Gültig',
      dataIndex: 'valid',
      width: 100,
      render: (isValid: boolean) => <Checkbox checked={isValid} />,
      ...getBooleanSearch<Campaign>('valid'),
    },
    {
      title: 'Frequenz',
      dataIndex: 'frequency',
      width: 100,
      sorter: (a, b) => a.frequency - b.frequency,
    },
    {
      title: 'Anz. Videos',
      dataIndex: 'videos',
      width: 100,
      render: (videos: any[]) => videos.length,
      sorter: (a, b) => a.videos.length - b.videos.length,
    },
    {
      title: 'Anz. Einzelstores',
      dataIndex: 'stores',
      width: 100,
      render: (stores: any[]) => stores.length,
      sorter: (a, b) => a.stores.length - b.stores.length,
    },
    {
      title: 'Anz. Store Gruppen',
      dataIndex: 'store_groups',
      width: 100,
      render: (storeGroups: any[]) => storeGroups.length,
      sorter: (a, b) => a.store_groups.length - b.store_groups.length,
    },
    {
      key: 'actions',
      width: 100,
      render: (_, campaign) => (
        <>
          <Button
            type="link"
            onClick={() => selectCampaign && selectCampaign(campaign.id)}
          >
            Öffnen
          </Button>
          <CampaignDeleteButton
            campaign={campaign}
            buttonType="link"
            onDelete={() => queryClient.invalidateQueries('/campaign')}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <CampaignCreateButton selectCampaign={selectCampaign} />
      <Table
        columns={columns}
        dataSource={campaigns}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default CampaignOverview;
