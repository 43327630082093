import { DeleteOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Comment,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  AliveReport,
  createStoreComment,
  getAliveReports,
  getStore,
  getStoreComments,
  removeStoreComment,
  StoreShallow,
} from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';
import EntityFieldRow from '../common/EntityFieldRow';
import ITVTag from '../common/ITVTag';
import DmmeSelect from '../dmme/DmmeSelect';
import StoreSelect from './StoreSelect';

const StoreStatus: FC = () => {
  const [storeId, setStoreId] = useState<string>();
  const [dmmeId, setDmmeId] = useState<string>();

  const { data: aliveReports, isLoading } = useQuery(
    ['/report/alive', { storeId, dmmeId }],
    () =>
      getAliveReports({ storeId, dmmeId }).then((res) =>
        res.status === 200 ? res.data : undefined
      )
  );

  const columns: ColumnsType<AliveReport> = [
    {
      title: 'DMME',
      dataIndex: 'dmme_serial_number',
      render: (dmmeSerialNumber: string) => <ITVTag>{dmmeSerialNumber}</ITVTag>,
    },
    {
      title: 'Store',
      dataIndex: 'store',
      render: (store?: StoreShallow) =>
        store ? <StorePreview storeId={store.id} /> : undefined,
    },
    {
      title: 'Status',
      dataIndex: 'last_alive',
      render: (lastAlive: string) => <StatusLamp lastAlive={lastAlive} />,
      sorter: (a, b) => a.last_alive.localeCompare(b.last_alive),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Info',
      key: 'info',
      render: (
        _,
        {
          cpu_percent: cpuPercent,
          memory_percent: memoryPercent,
          internal_ip: internalIp,
          external_ip: externalIp,
        }
      ) => (
        <InfoPopover
          cpuPercent={cpuPercent}
          memoryPercent={memoryPercent}
          internalIp={internalIp}
          externalIp={externalIp}
        />
      ),
    },
    {
      title: 'Kommentare',
      key: 'comments',
      render: (_, { store }) =>
        store ? <StoreCommentsPopover storeId={store.id} /> : undefined,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card bodyStyle={{ padding: 0 }}>
          <Collapse defaultActiveKey={['default']} ghost>
            <Collapse.Panel header="Filter" key="default">
              <EntityFieldRow
                label="Store"
                field={
                  <StoreSelect
                    value={storeId}
                    onChange={setStoreId}
                    allowClear
                  />
                }
              />
              <EntityFieldRow
                label="DMME"
                field={
                  <DmmeSelect value={dmmeId} onChange={setDmmeId} allowClear />
                }
              />
            </Collapse.Panel>
          </Collapse>
        </Card>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={aliveReports}
          loading={isLoading}
          size="small"
        />
      </Col>
    </Row>
  );
};

type StorePreviewProps = {
  storeId: string;
};

const StorePreview: FC<StorePreviewProps> = ({ storeId }) => {
  const { data: store } = useQuery(['/store', { id: storeId }], () =>
    getStore(storeId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  return store ? (
    <>
      {`${store.store_number} - ${store.city}, ${store.address}, ${store.zip_code}`}
    </>
  ) : (
    <></>
  );
};

type StatusLampProps = {
  lastAlive: string;
};

const StatusLamp: FC<StatusLampProps> = ({ lastAlive }) => {
  const nowMoment = moment();
  const lastAliveMoment = moment(lastAlive);

  const duration = moment.duration(nowMoment.diff(lastAliveMoment));
  const good = duration.asMinutes() < 10;

  return (
    <Badge text={`${duration.humanize()} ago`} color={good ? 'green' : 'red'} />
  );
};

type InfoPopoverProps = {
  cpuPercent: number;
  memoryPercent: number;
  internalIp: string;
  externalIp: string;
};

const InfoPopover: FC<InfoPopoverProps> = ({
  cpuPercent,
  memoryPercent,
  internalIp,
  externalIp,
}) => {
  return (
    <Popover
      title="Informationen"
      content={
        <Space direction="vertical">
          <Statistic title="CPU" value={cpuPercent} suffix="%" />
          <Statistic title="RAM" value={memoryPercent} suffix="%" />
          <Statistic title="IP (intern)" value={internalIp} />
          <Statistic title="IP (extern)" value={externalIp} />
        </Space>
      }
      trigger="click"
      placement="bottomLeft"
      overlayStyle={{ width: 360 }}
    >
      <Button>Info</Button>
    </Popover>
  );
};

type StoreCommentsPopoverProps = {
  storeId: string;
};

type StoreCommentCreateFormFields = {
  content: string;
};

const StoreCommentsPopover: FC<StoreCommentsPopoverProps> = ({ storeId }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { data: comments, isLoading } = useQuery(
    ['/store_comment', storeId],
    () =>
      getStoreComments({ storeId }).then((res) =>
        res.status === 200 ? res.data : undefined
      ),
    { enabled: isPopoverVisible }
  );

  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();
  const submitForm = ({ content }: StoreCommentCreateFormFields) => {
    createStoreComment({
      content,
      store: { id: storeId },
    })
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries(['/store_comment', storeId]);
          formRef.resetFields();
        }
        return res;
      })
      .then(showMessageOnError);
  };

  const handleDelete = (storeCommentId: string) =>
    removeStoreComment(storeCommentId).then((res) => {
      if (res.status === 200) {
        queryClient.invalidateQueries(['/store_comment', storeId]);
      }
    });

  return (
    <Popover
      title="Kommentare"
      content={
        <>
          {isLoading && <Spin />}
          {comments
            ?.sort((a, b) => a.datetime.localeCompare(b.datetime))
            .map((comment) => (
              <Comment
                key={comment.id}
                content={comment.content}
                datetime={moment(comment.datetime).format('YYYY-MM-DD HH:mm')}
                actions={[
                  <span onClick={() => handleDelete(comment.id)}>
                    <DeleteOutlined /> Löschen
                  </span>,
                ]}
              />
            ))}
          <Divider
            orientation="left"
            style={{ fontWeight: 'normal', fontSize: 12 }}
          >
            Kommentar schreiben
          </Divider>
          <Form form={formRef} layout="horizontal" onFinish={submitForm}>
            <Form.Item name="content" rules={[{ required: true }]}>
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Add Comment
              </Button>
            </Form.Item>
          </Form>
        </>
      }
      trigger="click"
      placement="bottomLeft"
      onVisibleChange={setIsPopoverVisible}
      overlayStyle={{ width: 360 }}
      overlayInnerStyle={{ maxHeight: '50vh', overflowY: 'auto' }}
    >
      <Button>Kommentare</Button>
    </Popover>
  );
};

export default StoreStatus;
