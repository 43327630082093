import { Alert, Col, Row } from 'antd';
import { FC } from 'react';
import CommandGeneratePlaylists from './CommandGeneratePlaylists';
import CommandLoadStores from './CommandLoadStores';

const CommandGrid: FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          type="error"
          message="Diese Seite sollte im normalen Betrieb nicht genutzt werden und kann zu einer erhöhten Last auf dem System führen."
        />
      </Col>
      <Col span={12}>
        <CommandLoadStores />
      </Col>
      <Col span={12}>
        <CommandGeneratePlaylists />
      </Col>
    </Row>
  );
};

export default CommandGrid;
