import { Button, Empty, Input, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideos } from '../../utilities/api';
import useSelectVideo from '../../utilities/use-select-video';

type VideoSelectProps = {
  value?: string;
  onChange?: (videoId?: string) => void;
  allowClear?: boolean;
};

const VideoSelect: FC<VideoSelectProps> = ({
  value,
  onChange,
  allowClear = false,
}) => {
  const selectVideo = useSelectVideo();

  const { data: videos, isLoading } = useQuery(['/video'], () =>
    getVideos().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const options = videos?.map((video) => ({
    value: video.id,
    label: video.name,
  }));

  return (
    <Input.Group style={{ width: '100%', display: 'flex' }} compact>
      <Select
        style={{ width: '100%' }}
        placeholder="Video auswählen"
        value={value}
        onChange={onChange}
        options={options}
        showSearch
        filterOption={(input, option) =>
          option?.label
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
        }
        allowClear={allowClear}
        notFoundContent={
          isLoading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
      />
      <Button disabled={!value} onClick={() => value && selectVideo(value)}>
        Zum Video
      </Button>
    </Input.Group>
  );
};

export default VideoSelect;
