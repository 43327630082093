import { Button, Card, Result, Spin } from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { pullStores } from '../../utilities/api';

type CommandState = undefined | 'ERROR' | 'SUCCESS';

const CommandLoadStores: FC = () => {
  const queryClient = useQueryClient();

  const [storesLoading, setStoresLoading] = useState(false);
  const [storesCommandState, setStoresCommandState] = useState<CommandState>();

  const loadStores = async () => {
    setStoresLoading(true);

    await pullStores()
      .then(() => setStoresCommandState('SUCCESS'))
      .then(() => queryClient.invalidateQueries('/store'))
      .catch(() => setStoresCommandState('ERROR'));

    setStoresLoading(false);
  };

  return (
    <Card title="Stores nachladen" style={{ textAlign: 'center' }}>
      <Button
        size="large"
        disabled={storesLoading}
        onClick={() => loadStores()}
      >
        Stores nachladen
      </Button>
      <br />
      <br />
      {storesLoading && <Spin size="large" />}
      {storesCommandState === 'SUCCESS' && (
        <Result status="success" title="Erfolgreich Stores geladen!" />
      )}
      {storesCommandState === 'ERROR' && (
        <Result status="error" title="Fehler beim Laden der Stores!" />
      )}
    </Card>
  );
};

export default CommandLoadStores;
