import { Empty, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideoGenres } from '../../utilities/api';

type VideoGenreSelectProps = {
  value?: string;
  onChange?: (videoGenreId: string) => void;
};

const VideoGenreSelect: FC<VideoGenreSelectProps> = ({ value, onChange }) => {
  const { data: videoGenres, isLoading } = useQuery(['/video-genre'], () =>
    getVideoGenres().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const options = videoGenres?.map((videoGenre) => ({
    value: videoGenre.id,
    label: videoGenre.name,
  }));

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Video Genre auswählen"
      value={value}
      onChange={onChange}
      options={options}
      showSearch
      filterOption={(input, option) =>
        option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
        false
      }
      notFoundContent={
        isLoading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  );
};

export default VideoGenreSelect;
