import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeSlot, Slot } from '../../utilities/api';

type SlotDeleteButtonProps = {
  slot: Slot;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const SlotDeleteButton: FC<SlotDeleteButtonProps> = ({
  slot,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${slot.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeSlot(slot.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default SlotDeleteButton;
