import { Card, InputNumber, Typography } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getSlot, updateSlot } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import VideoSelect from '../video/VideoSelect';

type SlotSingleProps = {
  slotId: string;
};

const SlotSingle: FC<SlotSingleProps> = ({ slotId }) => {
  const queryClient = useQueryClient();

  const { data: slot } = useQuery(['/slot', { id: slotId }], () =>
    getSlot(slotId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/slot');
    }
  };

  const editName = (name: string) =>
    updateSlot(slotId, { name }).then(invalidateOnSuccess);

  const editDescription = (description: string) =>
    updateSlot(slotId, { description }).then(invalidateOnSuccess);

  const editDuration = (duration: number) =>
    updateSlot(slotId, { duration }).then(invalidateOnSuccess);

  const editFrequency = (frequency: number) =>
    updateSlot(slotId, { frequency }).then(invalidateOnSuccess);

  const editVideo = (videoId: string) =>
    updateSlot(slotId, { video: { id: videoId } }).then(invalidateOnSuccess);

  return (
    <Card title={slot?.name}>
      <EntityFieldRow
        label="Name"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editName }}
          >
            {slot?.name || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Beschreibung"
        field={
          <Typography.Paragraph
            editable={{ tooltip: 'Bearbeiten', onChange: editDescription }}
          >
            {slot?.description || ''}
          </Typography.Paragraph>
        }
      />
      <EntityFieldRow
        label="Dauer [ms]"
        field={
          <>
            <InputNumber
              value={slot?.duration}
              onChange={(value) => editDuration(Number(value))}
              min={0}
            />
          </>
        }
      />
      <EntityFieldRow
        label="Frequenz"
        field={
          <InputNumber
            value={slot?.frequency}
            onChange={(value) => editFrequency(Number(value))}
            min={0}
          />
        }
      />
      <EntityFieldRow
        label="Video"
        field={
          <VideoSelect
            value={slot?.video?.id}
            onChange={(videoId) => videoId && editVideo(videoId)}
          />
        }
      />
    </Card>
  );
};

export default SlotSingle;
