import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';
import DmmeTag from './DmmeTag';

type DmmeTagListProps = {
  dmmeIds: string[];
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const DmmeTagList: FC<DmmeTagListProps> = ({ dmmeIds, color }) => (
  <>
    {dmmeIds.map((dmmeId) => (
      <DmmeTag key={dmmeId} dmmeId={dmmeId} color={color} />
    ))}
  </>
);

export default DmmeTagList;
