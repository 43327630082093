import { Card, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideoGenres, VideoGenre } from '../../utilities/api';

const VideoGenreMixerWeightTable: FC = () => {
  const { data: videoGenres } = useQuery(['/video-genre'], () =>
    getVideoGenres().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<VideoGenre> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Anteil',
      dataIndex: 'weight',
      render: (value: number) => `${value}%`,
    },
    {
      title: 'pro Stunde*',
      key: 'time',
      render: (_, videoGenre) =>
        `${(videoGenre.weight * 0.6 * 0.8).toFixed(2)} min`,
      align: 'right',
    },
  ];

  return (
    <Card>
      <Table rowKey="id" dataSource={videoGenres} columns={columns} />*
      abzüglich 20% Werbeanteil.
    </Card>
  );
};

export default VideoGenreMixerWeightTable;
