import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideoGenre, VideoGenre } from '../../utilities/api';
import ITVTag from '../common/ITVTag';

type VideoGenreTagProps = {
  videoGenreId: string;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const VideoGenreTag: FC<VideoGenreTagProps> = ({ videoGenreId, color }) => {
  const { data: videoGenre } = useQuery(
    ['/video-genre', { id: videoGenreId }],
    () => getVideoGenre(videoGenreId).then((res) => res.data as VideoGenre)
  );

  return <ITVTag color={color}>{videoGenre?.name}</ITVTag>;
};

export default VideoGenreTag;
