import moment from 'moment';
import { FC } from 'react';

type TimeProps = {
  milliseconds: number;
};

const Time: FC<TimeProps> = ({ milliseconds: ms }) => {
  const duration = moment.duration({ milliseconds: ms });

  const minutes = Math.floor(duration.asMinutes());
  const seconds = duration.seconds();
  const milliseconds = duration.milliseconds();

  const time = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;

  return <>{time}</>;
};

export default Time;
