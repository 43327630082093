import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { useQuery } from 'react-query';
import { FC } from 'react';
import { getStore } from '../../utilities/api';
import ITVTag from '../common/ITVTag';

type StoreTagProps = {
  storeId: string;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const StoreTag: FC<StoreTagProps> = ({ storeId, color }) => {
  const { data: store } = useQuery(['/store', { id: storeId }], () =>
    getStore(storeId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const storeStr = store
    ? `${store.store_number} - ${store.city}, ${store.address}, ${store.zip_code}`
    : undefined;

  return <ITVTag color={color}>{storeStr}</ITVTag>;
};

export default StoreTag;
