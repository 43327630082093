import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import VideoGenreSingle from '../../components/video-genre/VideoGenreSingle';

type VideoGenreSinglePageProps = {
  videoGenreId: string;
};

const VideoGenreSinglePage: FC<
  RouteConfigComponentProps<VideoGenreSinglePageProps>
> = ({ match }) => {
  const { videoGenreId } = match.params;

  return <VideoGenreSingle videoGenreId={videoGenreId} />;
};

export default VideoGenreSinglePage;
