import { Pie } from '@ant-design/charts';
import { Card } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getVideoGenres } from '../../utilities/api';

const VideoGenreMixerWeightDiagram: FC = () => {
  const { data: videoGenres } = useQuery(['/video-genre'], () =>
    getVideoGenres().then((res) => (res.status === 200 ? res.data : undefined))
  );

  return (
    <Card>
      <Pie
        appendPadding={10}
        data={videoGenres || []}
        angleField="weight"
        colorField="name"
        radius={1}
        innerRadius={0.6}
        label={{
          type: 'inner',
          offset: '-50%',
          style: { textAlign: 'center' },
          autoRotate: false,
          content: '{value}',
        }}
        interactions={[
          { type: 'element-selected' },
          { type: 'element-active' },
          { type: 'pie-statistic-active' },
        ]}
        statistic={{
          title: false,
        }}
      />
    </Card>
  );
};

export default VideoGenreMixerWeightDiagram;
