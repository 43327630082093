import { Tag, Typography } from 'antd';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';

type ITVTagProps = {
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const ITVTag: FC<ITVTagProps> = ({ color, children }) => (
  <Tag style={{ maxWidth: '100%' }} color={color || 'geekblue'}>
    <Typography.Text
      style={{ maxWidth: '100%' }}
      ellipsis
      title={String(children)}
    >
      {children}
    </Typography.Text>
  </Tag>
);

export default ITVTag;
