import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './routes';
import { defaults as apiDefaults } from './utilities/api';
import getBaseUrl from './utilities/get-base-url';
import { setAuthHeaders } from './utilities/auth';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'antd/dist/antd.css';

apiDefaults.baseUrl = getBaseUrl();
const bearerToken = localStorage.getItem('Authorization') || undefined;
setAuthHeaders(bearerToken);

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root')
);
