import { Card } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getStore, updateStore } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import DmmeTag from '../dmme/DmmeTag';
import StoreGroupMultiSelect from '../store-group/StoreGroupMultiSelect';

type StoreSingleProps = {
  storeId: string;
};

const StoreSingle: FC<StoreSingleProps> = ({ storeId }) => {
  const queryClient = useQueryClient();

  const { data: store } = useQuery(['/store', { id: storeId }], () =>
    getStore(storeId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/store');
    }
  };

  const editStoreGroups = (storeGroupIds: string[]) =>
    updateStore(storeId, {
      store_groups: storeGroupIds.map((id) => ({ id })),
    }).then(invalidateOnSuccess);

  return (
    <Card title={`${store?.store_number} - ${store?.company}`}>
      <EntityFieldRow label="Store Nummer" field={store?.store_number} />
      <EntityFieldRow label="Firma" field={store?.company} />
      <EntityFieldRow label="PLZ" field={store?.zip_code} />
      <EntityFieldRow label="Stadt" field={store?.city} />
      <EntityFieldRow label="Adresse" field={store?.address} />
      <EntityFieldRow
        label="DMMEs"
        field={store?.dmmes.map((dmme) => (
          <DmmeTag key={dmme.id} dmmeId={dmme.id} />
        ))}
      />
      <EntityFieldRow
        label="Store Gruppen"
        field={
          <StoreGroupMultiSelect
            onChange={editStoreGroups}
            values={store?.store_groups.map(({ id }) => id)}
          />
        }
      />
    </Card>
  );
};

export default StoreSingle;
