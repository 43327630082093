import { Card, Col, DatePicker, Row, Typography } from 'antd';
import moment from 'moment';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC } from 'react';
import ReactPlayer from 'react-player';
import { useQuery, useQueryClient } from 'react-query';
import { getVideo, updateVideo } from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import Time from '../common/Time';
import VideoGenreSelect from '../video-genre/VideoGenreSelect';

type VideoSingleProps = {
  videoId: string;
};

const VideoSingle: FC<VideoSingleProps> = ({ videoId }) => {
  const queryClient = useQueryClient();

  const { data: video } = useQuery(['/video-genre', { id: videoId }], () =>
    getVideo(videoId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/video-genre');
    }
  };

  const editName = (name: string) =>
    updateVideo(videoId, { name }).then(invalidateOnSuccess);

  const editSupplier = (supplier: string) =>
    updateVideo(videoId, { supplier }).then(invalidateOnSuccess);

  const editValidityStart = (validityStart?: moment.Moment | null) => {
    if (validityStart) {
      updateVideo(videoId, {
        validity_start: validityStart.toISOString(true).slice(0, 10),
      }).then(invalidateOnSuccess);
    }
  };

  const editValidityEnd = (validityEnd?: moment.Moment | null) =>
    updateVideo(videoId, {
      validity_end: validityEnd?.toISOString(true).slice(0, 10) || null,
    }).then(invalidateOnSuccess);

  const editGenre = (genreId: string) =>
    updateVideo(videoId, { genre: { id: genreId } }).then(invalidateOnSuccess);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title={video?.name}>
          <EntityFieldRow
            label="Name"
            field={
              <Typography.Paragraph
                editable={{ tooltip: 'Bearbeiten', onChange: editName }}
              >
                {video?.name || ''}
              </Typography.Paragraph>
            }
          />
          <EntityFieldRow
            label="Zulieferer"
            field={
              <Typography.Paragraph
                editable={{ tooltip: 'Bearbeiten', onChange: editSupplier }}
              >
                {video?.supplier || ''}
              </Typography.Paragraph>
            }
          />
          <EntityFieldRow
            label="Gültigkeit Start"
            field={
              <DatePicker
                value={
                  video?.validity_start
                    ? moment(video?.validity_start)
                    : undefined
                }
                onChange={editValidityStart}
                allowClear={false}
                placeholder="Startdatum"
              />
            }
          />
          <EntityFieldRow
            label="Gültigkeit Ende"
            field={
              <DatePicker
                value={
                  video?.validity_end ? moment(video?.validity_end) : undefined
                }
                onChange={editValidityEnd}
                placeholder="Enddatum"
              />
            }
          />
          <EntityFieldRow
            label="Video Genre"
            field={
              <VideoGenreSelect value={video?.genre?.id} onChange={editGenre} />
            }
          />
          <EntityFieldRow
            label="Dauer"
            field={<Time milliseconds={video?.duration || 0} />}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <ReactPlayer url={video?.external_link} controls />
          <br />
          <Typography.Text copyable>{video?.external_link}</Typography.Text>
        </Card>
      </Col>
    </Row>
  );
};

export default VideoSingle;
