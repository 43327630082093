import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import objectPath from 'object-path';

type StringSearchReturn<T> = {
  filterDropdown: (props: FilterDropdownProps) => React.ReactNode;
  filterIcon: (filtered: boolean) => React.ReactNode;
  onFilter: (value: string | number | boolean, record: T) => boolean;
  onFilterDropdownVisibleChange: (visible: boolean) => void;
};

function getStringSearch<T extends object>(
  dataIndex: string,
  title: string
): StringSearchReturn<T> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input.Search
          placeholder={`Durchsuche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedKeys(value ? [value] : []);
            if (!value) {
              clearFilters?.();
            }
          }}
          enterButton
          onSearch={() => confirm()}
          onPressEnter={() => confirm()}
          allowClear
          style={{ width: 300, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
    ),
    onFilter: (value, record) => {
      const obj = objectPath.get(record, dataIndex);
      if (obj) {
        return obj
          .toString()
          .toLowerCase()
          .includes(String(value).toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: () => undefined,
  };
}

export default getStringSearch;
