import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import PlaylistSingle from '../../components/playlist/PlaylistSingle';

type PlaylistSinglePageProps = {
  playlistId: string;
};

const PlaylistSinglePage: FC<
  RouteConfigComponentProps<PlaylistSinglePageProps>
> = ({ match }) => {
  const { playlistId } = match.params;

  return <PlaylistSingle playlistId={playlistId} />;
};

export default PlaylistSinglePage;
