import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getUsers, User } from '../../utilities/api';
import getStringSearch from '../table-search/string-search';
import UserCreateButton from './UserCreateButton';
import UserDeleteButton from './UserDeleteButton';

type UserOverviewProps = {
  selectUser?: (userId: string) => void;
};

const UserOverview: FC<UserOverviewProps> = ({ selectUser }) => {
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const { data: users, isLoading } = useQuery(['/user'], () =>
    getUsers().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const columns: ColumnsType<User> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      ...getStringSearch<User>('name', 'Name'),
      sorter: (a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || -1,
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      ellipsis: true,
      ...getStringSearch<User>('email', 'E-Mail'),
      sorter: (a, b) =>
        a.email?.toLowerCase().localeCompare(b.email?.toLowerCase() || '') ||
        -1,
    },
    {
      key: 'actions',
      render: (_, user) => (
        <>
          <Button type="link" onClick={() => selectUser && selectUser(user.id)}>
            Öffnen
          </Button>
          <UserDeleteButton
            user={user}
            buttonType="link"
            onDelete={() => queryClient.invalidateQueries('/user')}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <UserCreateButton selectUser={selectUser} />
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
          current: page + 1,
          onChange: (newPage) => setPage(newPage - 1),
        }}
      />
    </>
  );
};

export default UserOverview;
