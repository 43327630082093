import { FC } from 'react';
import { useHistory } from 'react-router';
import StoreGroupOverview from '../../components/store-group/StoreGroupOverview';

const StoreGroupOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <StoreGroupOverview
      selectStoreGroup={(storeGroupId) =>
        history.push(`/store-group/${storeGroupId}`)
      }
    />
  );
};

export default StoreGroupOverviewPage;
