import { Empty, Select, Spin } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getStores } from '../../utilities/api';

type StoreSelectProps = {
  value?: string;
  onChange?: (storeId?: string) => void;
  allowClear?: boolean;
};

const StoreSelect: FC<StoreSelectProps> = ({
  value,
  onChange,
  allowClear = false,
}) => {
  const { data: stores, isLoading } = useQuery(['/store'], () =>
    getStores().then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
  );

  const options = stores?.map((store) => ({
    value: store.id,
    label: `${store.store_number} - ${store.city}, ${store.address}, ${store.zip_code}`,
  }));

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Store auswählen"
      value={value}
      onChange={onChange}
      options={options}
      showSearch
      filterOption={(input, option) =>
        option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
        false
      }
      allowClear={allowClear}
      notFoundContent={
        isLoading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  );
};

export default StoreSelect;
