import { Button, Card, Col, Row, Slider, Typography } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';
import { FC, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getVideoGenres, updateVideoGenreWeights } from '../../utilities/api';

const VideoGenreMixer: FC = () => {
  const queryClient = useQueryClient();

  const { data: videoGenres } = useQuery(['/video-genre'], () =>
    getVideoGenres().then((res) => (res.status === 200 ? res.data : undefined))
  );

  const [weights, setWeights] = useState<number[]>();
  const [initalWeights, setInitialWeights] = useState<number[]>();
  useEffect(() => {
    setWeights(videoGenres?.map((videoGenre) => videoGenre.weight));
    setInitialWeights(videoGenres?.map((videoGenre) => videoGenre.weight));
  }, [videoGenres]);

  const updateWeight = (weight: number, index: number) => {
    setWeights(
      weights?.map((oldWeight, oldIndex) =>
        index === oldIndex ? weight : oldWeight
      )
    );
  };

  const weightSum = weights?.reduce((prev, curr) => prev + curr, 0);

  const invalidateOnSuccess = (res: ApiResponse) => {
    if (res.status === 200) {
      queryClient.invalidateQueries('/video-genre');
    }
  };

  const editWeights = () => {
    const weightUpdate =
      videoGenres?.map((videoGenre, index) => ({
        id: videoGenre.id,
        weight: weights ? weights[index] : 0,
      })) || [];

    updateVideoGenreWeights(weightUpdate).then(invalidateOnSuccess);
  };

  return (
    <Card title="Video Genre Mixer">
      {videoGenres?.map((videoGenre, index) => (
        <Row key={videoGenre.id} gutter={[8, 8]}>
          <Col span={4}>{videoGenre.name}</Col>
          <Col span={20}>
            <Slider
              marks={
                initalWeights
                  ? { [initalWeights[index]]: [initalWeights[index]] }
                  : {}
              }
              onChange={(weight: number) => updateWeight(weight, index)}
              value={weights ? weights[index] : 0}
            />
          </Col>
        </Row>
      ))}
      <Row gutter={[8, 8]}>
        <Col span={4}>Summe</Col>
        <Col span={20}>{weightSum}</Col>
      </Row>
      <Button
        style={{ marginTop: 16 }}
        type="primary"
        disabled={weightSum !== 100}
        onClick={editWeights}
      >
        Speichern
      </Button>
      <br />
      {weightSum !== 100 && (
        <Typography.Text type="danger">
          Gesamtgewicht beträgt nicht 100!
        </Typography.Text>
      )}
    </Card>
  );
};

export default VideoGenreMixer;
