import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import SlotSingle from '../../components/slot/SlotSingle';

type SlotSinglePageProps = {
  slotId: string;
};

const SlotSinglePage: FC<RouteConfigComponentProps<SlotSinglePageProps>> = ({
  match,
}) => {
  const { slotId } = match.params;

  return <SlotSingle slotId={slotId} />;
};

export default SlotSinglePage;
