import { Layout } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { getUserMe } from '../../utilities/api';
import './BasicLayout.css';
import SideMenu from './SideMenu';

const { Content } = Layout;

const BasicLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  const { data: me, isLoading } = useQuery('/user/me', () =>
    getUserMe().then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return undefined;
    })
  );

  if (!me && !isLoading) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Layout className="BasicLayout">
      <SideMenu />
      <Layout className="site-layout">
        <Content className="content">{renderRoutes(route?.routes)}</Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
