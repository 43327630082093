import { Col, Row } from 'antd';
import { FC } from 'react';
import VideoGenreMixer from './VideoGenreMixer';
import VideoGenreMixerWeightDiagram from './VideoGenreMixerWeightDiagram';
import VideoGenreMixerWeightTable from './VideoGenreMixerWeightTable';

const VideoGenreMixerGrid: FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <VideoGenreMixer />
      </Col>
      <Col span={12}>
        <VideoGenreMixerWeightDiagram />
      </Col>
      <Col span={12}>
        <VideoGenreMixerWeightTable />
      </Col>
    </Row>
  );
};

export default VideoGenreMixerGrid;
