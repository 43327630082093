import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createStoreGroup } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';
import StoreMultiSelect from '../store/StoreMultiSelect';

type StoreGroupCreateButtonProps = {
  selectStoreGroup?: (storeGroupId: string) => void;
};

type StoreGroupCreateFormFields = {
  name: string;
  description?: string;
  default?: boolean;
  storesIds?: string[];
};

const StoreGroupCreateButton: FC<StoreGroupCreateButtonProps> = ({
  selectStoreGroup,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const submitForm = (formFields: StoreGroupCreateFormFields) => {
    createStoreGroup({
      ...formFields,
      default: !!formFields.default,
      stores: formFields.storesIds?.map((id) => ({ id })) || [],
    })
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/store-group');
          formRef.resetFields();
          setModalVisible(false);
          selectStoreGroup && selectStoreGroup(res.data.id);
        }
        return res;
      })
      .then(showMessageOnError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Store Gruppe erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Store Gruppe erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Beschreibung">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="default" label="Standard" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="storesIds" label="Stores">
            <StoreMultiSelect />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default StoreGroupCreateButton;
