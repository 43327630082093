import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeStoreGroup, StoreGroup } from '../../utilities/api';

type StoreGroupDeleteButtonProps = {
  storeGroup: StoreGroup;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const StoreGroupDeleteButton: FC<StoreGroupDeleteButtonProps> = ({
  storeGroup,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${storeGroup.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeStoreGroup(storeGroup.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default StoreGroupDeleteButton;
