import { DatePicker, Empty, Input, Select, Spin } from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getPlaylistsByDate } from '../../utilities/api';

type PlaylistSelectProps = {
  value?: string;
  onChange?: (playlistId?: string) => void;
  allowClear?: boolean;
};

const PlaylistSelect: FC<PlaylistSelectProps> = ({
  value,
  onChange,
  allowClear = false,
}) => {
  const [date, setDate] = useState(moment());
  const dateString = date.toISOString(true).slice(0, 10);

  const { data: playlists, isLoading } = useQuery(
    ['/playlist', { date, offset: 0, limit: 10000 }],
    () =>
      getPlaylistsByDate({ date: dateString, offset: 0, limit: 10000 }).then(
        (res) => (res.status === 200 ? res.data : undefined)
      )
  );

  const options = playlists?.result.map((playlist) => ({
    value: playlist.id,
    label: playlist.name,
  }));

  return (
    <Input.Group style={{ width: '100%', display: 'flex' }} compact>
      <DatePicker
        value={date}
        onChange={(value) => value && setDate(value)}
        allowClear={false}
      />
      <Select
        style={{ width: '100%' }}
        placeholder="Playlist auswählen"
        value={value}
        onChange={onChange}
        options={options}
        showSearch
        filterOption={(input, option) =>
          option?.label
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
        }
        allowClear={allowClear}
        notFoundContent={
          isLoading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
      />
    </Input.Group>
  );
};

export default PlaylistSelect;
