import { Col, Row } from 'antd';
import { FC } from 'react';

type EntityFieldRowProps = {
  label?: string;
  field?: React.ReactElement | React.ReactElement[] | string | number;
};

const EntityFieldRow: FC<EntityFieldRowProps> = ({ label, field }) => {
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }} align="middle">
      <Col span={4} style={{ textAlign: 'right' }}>
        {label}
        {label && <>:</>}
      </Col>
      <Col span={20}>{field}</Col>
    </Row>
  );
};

export default EntityFieldRow;
