import { Button, Card, DatePicker, Result, Space, Spin } from 'antd';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generatePlaylists } from '../../utilities/api';

type CommandState = undefined | 'ERROR' | 'SUCCESS';

const CommandGeneratePlaylists: FC = () => {
  const queryClient = useQueryClient();

  const [date, setDate] = useState(moment());
  const dateString = date.toISOString(true).slice(0, 10);

  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateCommandState, setGenerateCommandState] =
    useState<CommandState>();

  const generate = async () => {
    setGenerateLoading(true);

    await generatePlaylists({ date: dateString })
      .then(() => setGenerateCommandState('SUCCESS'))
      .then(() => queryClient.invalidateQueries('/playlist'))
      .catch(() => setGenerateCommandState('ERROR'));

    setGenerateLoading(false);
  };

  return (
    <Card title="Playlists generieren" style={{ textAlign: 'center' }}>
      <Space>
        <DatePicker
          size="large"
          value={date}
          onChange={(value) => value && setDate(value)}
          allowClear={false}
        />
        <Button
          size="large"
          disabled={generateLoading}
          onClick={() => generate()}
        >
          Playlists generieren
        </Button>
      </Space>
      <br />
      <br />
      {generateLoading && <Spin size="large" />}
      {generateCommandState === 'SUCCESS' && (
        <Result status="success" title="Erfolgreich Playlists generiert!" />
      )}
      {generateCommandState === 'ERROR' && (
        <Result status="error" title="Fehler beim Playlist generieren!" />
      )}
    </Card>
  );
};

export default CommandGeneratePlaylists;
