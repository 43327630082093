import { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';
import { useQueryClient } from 'react-query';
import { LoginOutlined } from '@ant-design/icons';
import { login } from '../../utilities/api';
import { removeAuthHeaders, setAuthHeaders } from '../../utilities/auth';
import { useHistory } from 'react-router';

import './LoginPage.css';

type LoginFormFields = {
  email: string;
  password: string;
};

type LoginState = false | true | 'PROCESSING' | 'FAILURE';

const LoginPage: FC = () => {
  const [loginState, setLoginState] = useState<LoginState>(false);
  const queryClient = useQueryClient();
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('Authorization');
    removeAuthHeaders();
    queryClient.removeQueries();
  }, []);

  const [loginForm] = Form.useForm<LoginFormFields>();

  const onLogin = () => history.push('/');

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): void => {
    setLoginState('PROCESSING');

    login({ username: email, password }).then((res) => {
      if (res.status === 200) {
        const bearerToken = `Bearer ${res.data.access_token}`;
        localStorage.setItem('Authorization', bearerToken);
        setAuthHeaders(bearerToken);
        onLogin();
      } else {
        setLoginState('FAILURE');
      }
    });
  };

  return (
    <div className="Login">
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 8, offset: 8 }}
          xxl={{ span: 6, offset: 9 }}
        >
          <Card title="Login">
            {loginState === 'PROCESSING' && (
              <Alert
                className="login-alert"
                message="Einloggen..."
                type="success"
                showIcon
              />
            )}
            {loginState === 'FAILURE' && (
              <Alert
                className="login-alert"
                message="Login fehlgeschlagen"
                type="error"
                showIcon
              />
            )}

            <Form form={loginForm} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label="E-Mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Bitte die E-Mail-Addresse eingeben!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Passwort"
                name="password"
                rules={[
                  { required: true, message: 'Bitte das Passwort eingeben!' },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  icon={<LoginOutlined />}
                  htmlType="submit"
                >
                  Anmelden
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
