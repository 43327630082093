import { FC } from 'react';
import { Line } from '@ant-design/charts';

type ReportChartProps = {
  data?: {
    date: string;
    value: number;
  }[];
};

const ReportChart: FC<ReportChartProps> = ({ data }) => (
  <Line
    data={data || []}
    xField="date"
    yField="value"
    padding="auto"
    xAxis={{ tickCount: 5 }}
  />
);

export default ReportChart;
