import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createVideoGenre } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';

type VideoGenreCreateButtonProps = {
  selectVideoGenre?: (videoGenreId: string) => void;
};

type VideoGenreCreateFormFields = {
  name: string;
};

const VideoGenreCreateButton: FC<VideoGenreCreateButtonProps> = ({
  selectVideoGenre,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const submitForm = (formFields: VideoGenreCreateFormFields) => {
    createVideoGenre(formFields)
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/video-genre');
          formRef.resetFields();
          setModalVisible(false);
          selectVideoGenre && selectVideoGenre(res.data.id);
        }
        return res;
      })
      .then(showMessageOnError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Video Genre erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Video Genre erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VideoGenreCreateButton;
