import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeVideo, Video } from '../../utilities/api';

type VideoDeleteButtonProps = {
  video: Video;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const VideoDeleteButton: FC<VideoDeleteButtonProps> = ({
  video,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${video.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeVideo(video.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default VideoDeleteButton;
