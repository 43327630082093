import { SearchOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import objectPath from 'object-path';

type DateSearchReturn<T> = {
  filterDropdown: (props: FilterDropdownProps) => React.ReactNode;
  filterIcon: (filtered: boolean) => React.ReactNode;
  onFilter: (value: string | number | boolean, record: T) => boolean;
  onFilterDropdownVisibleChange: (visible: boolean) => void;
};

function getDateSearch<T extends object>(
  dataIndex: string,
  title: string
): DateSearchReturn<T> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          placeholder={`Durchsuche ${title}`}
          value={selectedKeys.length ? moment(selectedKeys[0]) : undefined}
          onChange={(_, dateString) => {
            if (dateString !== '') {
              setSelectedKeys([dateString]);
              confirm?.();
            } else {
              clearFilters?.();
            }
          }}
          style={{ width: 300, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
    ),
    onFilter: (value, record) => {
      const obj = objectPath.get(record, dataIndex);
      if (obj) {
        return obj
          .toString()
          .toLowerCase()
          .includes(String(value).toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: () => undefined,
  };
}

export default getDateSearch;
