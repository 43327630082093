import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Typography,
} from 'antd';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createCampaign } from '../../utilities/api';
import { showMessageOnError } from '../../utilities/request-errors';
import StoreGroupMultiSelect from '../store-group/StoreGroupMultiSelect';
import StoreMultiSelect from '../store/StoreMultiSelect';
import VideoSelect from '../video/VideoSelect';
import CampaignMultiSelect from './CampaignMultiSelect';

// TODO alle required felder mit message versehen

type CampaignCreateButtonProps = {
  selectCampaign?: (campaignId: string) => void;
};

type CampaignCreateFormFields = {
  name: string;
  description?: string;
  validityStart: moment.Moment;
  validityEnd: moment.Moment;
  frequency: number;
  videos: {
    videoId: string;
    validityStart?: moment.Moment;
    validityEnd?: moment.Moment;
  }[];
  storeIds?: string[];
  storeGroupIds?: string[];
  similarIds?: string[];
};

const CampaignCreateButton: FC<CampaignCreateButtonProps> = ({
  selectCampaign,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const submitForm = (formFields: CampaignCreateFormFields) => {
    createCampaign({
      ...formFields,
      validity_start: formFields.validityStart.toISOString(true).slice(0, 10),
      validity_end: formFields.validityEnd.toISOString(true).slice(0, 10),
      videos: formFields.videos.map(
        ({ videoId, validityStart, validityEnd }) => ({
          video: { id: videoId },
          ...(validityStart
            ? { validity_start: validityStart.toISOString(true).slice(0, 10) }
            : {}),
          ...(validityEnd
            ? { validity_end: validityEnd.toISOString(true).slice(0, 10) }
            : {}),
        })
      ),
      stores: formFields.storeIds?.map((id) => ({ id })) || [],
      store_groups: formFields.storeGroupIds?.map((id) => ({ id })) || [],
      similar: formFields.similarIds?.map((id) => ({ id })) || [],
    })
      .then((res) => {
        if (res.status === 201) {
          queryClient.invalidateQueries('/campaign');
          formRef.resetFields();
          setModalVisible(false);
          selectCampaign && selectCampaign(res.data.id);
        }
        return res;
      })
      .then(showMessageOnError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusCircleOutlined />}
        style={{ marginRight: 16, marginBottom: 16 }}
      >
        Kampagne erstellen
      </Button>
      <Modal
        visible={modalVisible}
        title="Kampagne erstellen"
        onCancel={() => setModalVisible(false)}
        onOk={() => formRef.submit()}
        cancelText="Abbrechen"
        okText="Erstellen"
        width={800}
      >
        <Form
          form={formRef}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={submitForm}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Beschreibung">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="validityStart"
            label="Gültigkeit Start"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="Startdatum" />
          </Form.Item>
          <Form.Item
            name="validityEnd"
            label="Gültigkeit Ende"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="Enddatum" />
          </Form.Item>
          <Form.Item
            name="frequency"
            label="Frequenz"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item label="Videos">
            <Form.List
              name="videos"
              rules={[
                {
                  validator: async (_, items: any[]) => {
                    if (!items || items.length === 0) {
                      return Promise.reject(new Error('Mindestens 1 Video'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, fieldKey }) => (
                    <div key={key}>
                      <Form.Item
                        name={[name, 'videoId']}
                        fieldKey={[fieldKey, 'videoId']}
                        rules={[{ required: true }]}
                      >
                        <VideoSelect />
                      </Form.Item>
                      <Form.Item
                        name={[name, 'validityStart']}
                        fieldKey={[fieldKey, 'validityStart']}
                        style={{ display: 'inline-block', marginRight: 16 }}
                      >
                        <DatePicker placeholder="Startdatum" />
                      </Form.Item>
                      <Form.Item
                        name={[name, 'validityEnd']}
                        fieldKey={[fieldKey, 'validityEnd']}
                        style={{ display: 'inline-block', marginRight: 16 }}
                      >
                        <DatePicker placeholder="Enddatum" />
                      </Form.Item>

                      <Button
                        icon={<DeleteOutlined />}
                        type="text"
                        onClick={() => remove(name)}
                        style={{ float: 'right' }}
                      />
                      <Divider style={{ marginTop: -16, marginBottom: 16 }} />
                    </div>
                  ))}
                  <Button icon={<PlusCircleOutlined />} onClick={() => add()}>
                    Video hinzufügen
                  </Button>
                  <br />
                  <Typography.Text type="danger">{errors}</Typography.Text>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item name="storeIds" label="Stores">
            <StoreMultiSelect filter={{ hasVideo: true }} />
          </Form.Item>
          <Form.Item name="storeGroupIds" label="Store Gruppen">
            <StoreGroupMultiSelect />
          </Form.Item>
          <Form.Item name="similarIds" label="Ähnliche Kampagnen">
            <CampaignMultiSelect />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CampaignCreateButton;
