import { FC } from 'react';
import { useHistory } from 'react-router';
import StoreOverview from '../../components/store/StoreOverview';

const StoreOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <StoreOverview
      selectStore={(storeId) => history.push(`/store/${storeId}`)}
    />
  );
};

export default StoreOverviewPage;
