import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import CampaignSingle from '../../components/campaign/CampaignSingle';

type CampaignSinglePageProps = {
  campaignId: string;
};

const CampaignSinglePage: FC<
  RouteConfigComponentProps<CampaignSinglePageProps>
> = ({ match }) => {
  const { campaignId } = match.params;

  return <CampaignSingle campaignId={campaignId} />;
};

export default CampaignSinglePage;
