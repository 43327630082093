import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeUser, User } from '../../utilities/api';

type UserDeleteButtonProps = {
  user: User;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const UserDeleteButton: FC<UserDeleteButtonProps> = ({
  user,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${user.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeUser(user.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default UserDeleteButton;
