import { defaults as apiDefaults } from './api';

export const setAuthHeaders = (bearerToken?: string) => {
  apiDefaults.headers = {
    ...apiDefaults.headers,
    Authorization: bearerToken,
  };
};

export const removeAuthHeaders = () => {
  if (apiDefaults.headers) {
    delete apiDefaults.headers.Authorization;
  }
};
