/**
 * Core
 * 0.1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type Body_login_auth_login_post = {
    grant_type?: string;
    username: string;
    password: string;
    scope?: string;
    client_id?: string;
    client_secret?: string;
};
export type Token = {
    access_token: string;
    token_type: string;
};
export type ValidationError = {
    loc: string[];
    msg: string;
    "type": string;
};
export type HTTPValidationError = {
    detail?: ValidationError[];
};
export type User = {
    name: string;
    email: string;
    id: string;
    "type"?: "/user";
};
export type UserCreate = {
    name: string;
    email: string;
    password: string;
};
export type UserShallow = {
    id: string;
    "type"?: "/user";
};
export type UserUpdate = {
    name?: string;
    email?: string;
    password?: string;
};
export type VideoShallow = {
    id: string;
    "type"?: "/video";
};
export type CampaignToVideo = {
    video: VideoShallow;
    validity_start?: string | null;
    validity_end?: string | null;
};
export type StoreShallow = {
    id: string;
    "type"?: "/store";
};
export type StoreGroupShallow = {
    id: string;
    "type"?: "/store_group";
};
export type CampaignShallow = {
    id: string;
    "type"?: "/campaign";
};
export type Campaign = {
    name: string;
    description?: string;
    validity_start: string;
    validity_end: string;
    valid: boolean;
    frequency: number;
    videos: CampaignToVideo[];
    stores: StoreShallow[];
    store_groups: StoreGroupShallow[];
    similar: CampaignShallow[];
    id: string;
    "type"?: "/campaign";
};
export type CampaignCreate = {
    name: string;
    description?: string;
    validity_start: string;
    validity_end: string;
    frequency: number;
    videos: CampaignToVideo[];
    stores: StoreShallow[];
    store_groups: StoreGroupShallow[];
    similar: CampaignShallow[];
};
export type CampaignUpdate = {
    name?: string;
    description?: string;
    validity_start?: string;
    validity_end?: string;
    frequency?: number;
    videos?: CampaignToVideo[];
    stores?: StoreShallow[];
    store_groups?: StoreGroupShallow[];
    similar?: CampaignShallow[];
};
export type Message = {
    success: boolean;
    message?: string;
};
export type DMME = {
    serial_number: number;
    music_stream?: string;
    screen_size?: string;
    program?: "NO_PROGRAM" | "MCD_AUDIO_ONLY" | "MCD_AUDIO_ONLY_ISB" | "MCD_INSTORETV" | "MCD_INSTORETV_LOUNGE" | "MCD_ECKSTEIN" | "OFFLINE";
    store?: StoreShallow;
    id: string;
    "type"?: "/dmme";
};
export type PlaylistSmall = {
    name: string;
    duration: number;
    date: string;
    program: "NO_PROGRAM" | "MCD_AUDIO_ONLY" | "MCD_AUDIO_ONLY_ISB" | "MCD_INSTORETV" | "MCD_INSTORETV_LOUNGE" | "MCD_ECKSTEIN" | "OFFLINE";
    has_audio: boolean;
    has_video: boolean;
    dmme_count: number;
    store_count: number;
    item_count: number;
    id: string;
    "type"?: "/playlist";
};
export type PlaylistSmallResultListWrapper = {
    offset: number;
    limit: number;
    total: number;
    result: PlaylistSmall[];
};
export type DMMEShallow = {
    id: string;
    "type"?: "/dmme";
};
export type SlotShallow = {
    id: string;
    "type"?: "/slot";
};
export type Playlist = {
    name: string;
    duration: number;
    date: string;
    program: "NO_PROGRAM" | "MCD_AUDIO_ONLY" | "MCD_AUDIO_ONLY_ISB" | "MCD_INSTORETV" | "MCD_INSTORETV_LOUNGE" | "MCD_ECKSTEIN" | "OFFLINE";
    has_audio: boolean;
    has_video: boolean;
    dmmes: DMMEShallow[];
    stores: StoreShallow[];
    items: (VideoShallow | SlotShallow)[];
    id: string;
    "type"?: "/playlist";
};
export type VideoGenreShallow = {
    id: string;
    "type"?: "/video_genre";
};
export type PlaylistVideo = {
    id: string;
    "type"?: "/video";
    name: string;
    external_link: string;
    duration: number;
    genre?: VideoGenreShallow;
};
export type PlaylistSlot = {
    id: string;
    "type"?: "/slot";
    name: string;
    duration: number;
    video?: PlaylistVideo;
};
export type PlaylistDetailed = {
    name: string;
    duration: number;
    date: string;
    program: "NO_PROGRAM" | "MCD_AUDIO_ONLY" | "MCD_AUDIO_ONLY_ISB" | "MCD_INSTORETV" | "MCD_INSTORETV_LOUNGE" | "MCD_ECKSTEIN" | "OFFLINE";
    has_audio: boolean;
    has_video: boolean;
    dmmes: DMMEShallow[];
    stores: StoreShallow[];
    items: (PlaylistVideo | PlaylistSlot)[];
    id: string;
    "type"?: "/playlist";
};
export type PlaylistShallow = {
    id: string;
    "type"?: "/playlist";
};
export type DatePlays = {
    date: string;
    plays: number;
};
export type PlaysReport = {
    start: string;
    end: string;
    video?: VideoShallow;
    store?: StoreShallow;
    dmme?: DMMEShallow;
    playlist?: PlaylistShallow;
    total_plays: number;
    daily_plays: DatePlays[];
};
export type AliveReport = {
    last_alive: string;
    dmme_serial_number: string;
    store_number: string;
    dmme?: DMMEShallow;
    store?: StoreShallow;
    cpu_percent: number;
    memory_percent: number;
    internal_ip: string;
    external_ip: string;
};
export type Slot = {
    name: string;
    description?: string;
    duration: number;
    frequency: number;
    video?: VideoShallow;
    id: string;
    "type"?: "/slot";
};
export type SlotCreate = {
    name: string;
    description?: string;
    duration: number;
    frequency: number;
    video?: VideoShallow;
};
export type SlotUpdate = {
    name?: string;
    description?: string;
    duration?: number;
    frequency?: number;
    video?: VideoShallow | null;
};
export type StoreComment = {
    datetime: string;
    content: string;
    store: StoreShallow;
    id: string;
    "type"?: "/store_comment";
};
export type StoreCommentCreate = {
    content: string;
    store: StoreShallow;
};
export type StoreCommentShallow = {
    id: string;
    "type"?: "/store_comment";
};
export type StoreCommentUpdate = {
    content?: string;
    store?: StoreShallow;
};
export type StoreGroup = {
    name: string;
    description?: string;
    "default": boolean;
    stores: StoreShallow[];
    id: string;
    "type"?: "/store_group";
};
export type StoreGroupCreate = {
    name: string;
    description?: string;
    "default": boolean;
    stores: StoreShallow[];
};
export type StoreGroupUpdate = {
    name?: string;
    description?: string;
    "default"?: boolean;
    stores?: StoreShallow[];
};
export type Store = {
    store_number: string;
    company?: string;
    city?: string;
    zip_code?: string;
    address?: string;
    dmmes: DMMEShallow[];
    store_groups: StoreGroupShallow[];
    id: string;
    "type"?: "/store";
    has_audio: boolean;
    has_video: boolean;
};
export type StoreUpdate = {
    store_groups?: StoreGroupShallow[];
};
export type VideoGenreWeightUpdate = {
    id: string;
    "type"?: "/video_genre";
    weight: number;
};
export type VideoGenre = {
    name: string;
    weight: number;
    id: string;
    "type"?: "/video_genre";
};
export type VideoGenreCreate = {
    name: string;
};
export type VideoGenreUpdate = {
    name?: string;
};
export type Body_upload_video_file_video_binary_post = {
    file: Blob;
};
export type Video = {
    name: string;
    external_link: string;
    supplier?: string;
    validity_start: string;
    validity_end?: string;
    duration: number;
    sha1_hexdigest: string;
    genre?: VideoGenreShallow;
    created_at?: string;
    id: string;
    "type"?: "/video";
};
export type VideoUpdate = {
    name?: string;
    supplier?: string;
    validity_start?: string;
    validity_end?: string | null;
    genre?: VideoGenreShallow | null;
};
export type Body_update_video_file_video__video_id__binary_patch = {
    file: Blob;
};
/**
 * Login
 */
export function login(bodyLoginAuthLoginPost: Body_login_auth_login_post, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Token;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/auth/login", oazapfts.form({
        ...opts,
        method: "POST",
        body: bodyLoginAuthLoginPost
    }));
}
/**
 * Refresh Token
 */
export function refreshToken({ sessionId }: {
    sessionId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Token;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/auth/refresh-token", {
        ...opts
    });
}
/**
 * Logout
 */
export function logout({ sessionId }: {
    sessionId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/auth/logout", {
        ...opts,
        method: "POST"
    });
}
/**
 * Get User Me
 */
export function getUserMe(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    }>("/user/me", {
        ...opts
    });
}
/**
 * Create User
 */
export function createUser(userCreate: UserCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: User;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/user", oazapfts.json({
        ...opts,
        method: "POST",
        body: userCreate
    }));
}
/**
 * Get User
 */
export function getUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/user/${userId}`, {
        ...opts
    });
}
/**
 * Remove User
 */
export function removeUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/user/${userId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update User
 */
export function updateUser(userId: string, userUpdate: UserUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/user/${userId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: userUpdate
    }));
}
/**
 * Get Users
 */
export function getUsers(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User[];
    }>("/user/", {
        ...opts
    });
}
/**
 * Get Campaigns
 */
export function getCampaigns(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Campaign[];
    }>("/campaign", {
        ...opts
    });
}
/**
 * Create Campaign
 */
export function createCampaign(campaignCreate: CampaignCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Campaign;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/campaign", oazapfts.json({
        ...opts,
        method: "POST",
        body: campaignCreate
    }));
}
/**
 * Get Campaign
 */
export function getCampaign(campaignId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Campaign;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/campaign/${campaignId}`, {
        ...opts
    });
}
/**
 * Remove Campaign
 */
export function removeCampaign(campaignId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CampaignShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/campaign/${campaignId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Campaign
 */
export function updateCampaign(campaignId: string, campaignUpdate: CampaignUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Campaign;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/campaign/${campaignId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: campaignUpdate
    }));
}
/**
 * Download Campaign Booking Confirmation
 */
export function downloadCampaignBookingConfirmation(campaignId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/campaign/${campaignId}/booking_confirmation`, {
        ...opts
    });
}
/**
 * Pull Stores
 */
export function pullStores(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Message;
    }>("/command/pull_stores", {
        ...opts
    });
}
/**
 * Generate Playlists
 */
export function generatePlaylists({ date }: {
    date?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Message;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/command/generate_playlists${QS.query(QS.form({
        date
    }))}`, {
        ...opts
    });
}
/**
 * Get Dmme
 */
export function getDmme(dmmeId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DMME;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/dmme/${dmmeId}`, {
        ...opts
    });
}
/**
 * Get Dmmes
 */
export function getDmmes(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DMME[];
    }>("/dmme", {
        ...opts
    });
}
/**
 * Healthcheck
 */
export function healthcheck(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: any;
    }>("/healthcheck", {
        ...opts
    });
}
/**
 * Index
 */
export function index(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 301;
        data: any;
    }>("/", {
        ...opts
    });
}
/**
 * Get Playlists By Date
 */
export function getPlaylistsByDate({ date, hasAudio, hasVideo, storeId, dmmeCountOrderBy, storeCountOrderBy, itemCountOrderBy, offset, limit }: {
    date?: string;
    hasAudio?: boolean;
    hasVideo?: boolean;
    storeId?: string;
    dmmeCountOrderBy?: "DESC" | "ASC";
    storeCountOrderBy?: "DESC" | "ASC";
    itemCountOrderBy?: "DESC" | "ASC";
    offset?: number;
    limit?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PlaylistSmallResultListWrapper;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/playlist/date${QS.query(QS.form({
        date,
        has_audio: hasAudio,
        has_video: hasVideo,
        store_id: storeId,
        dmme_count_order_by: dmmeCountOrderBy,
        store_count_order_by: storeCountOrderBy,
        item_count_order_by: itemCountOrderBy,
        offset,
        limit
    }))}`, {
        ...opts
    });
}
/**
 * Get Playlist
 */
export function getPlaylist(playlistId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Playlist;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/playlist/${playlistId}`, {
        ...opts
    });
}
/**
 * Get Playlist Detailed
 */
export function getPlaylistDetailed(playlistId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PlaylistDetailed;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/playlist/${playlistId}/detailed`, {
        ...opts
    });
}
/**
 * Get Play Reports
 */
export function getPlayReports(start: string, end: string, { videoId, storeId, dmmeId, playlistId }: {
    videoId?: string;
    storeId?: string;
    dmmeId?: string;
    playlistId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PlaysReport;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/report/plays${QS.query(QS.form({
        start,
        end,
        video_id: videoId,
        store_id: storeId,
        dmme_id: dmmeId,
        playlist_id: playlistId
    }))}`, {
        ...opts
    });
}
/**
 * Get Alive Reports
 */
export function getAliveReports({ storeId, dmmeId }: {
    storeId?: string;
    dmmeId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AliveReport[];
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/report/alive${QS.query(QS.form({
        store_id: storeId,
        dmme_id: dmmeId
    }))}`, {
        ...opts
    });
}
/**
 * Get Slots
 */
export function getSlots(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Slot[];
    }>("/slot", {
        ...opts
    });
}
/**
 * Create Slot
 */
export function createSlot(slotCreate: SlotCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Slot;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/slot", oazapfts.json({
        ...opts,
        method: "POST",
        body: slotCreate
    }));
}
/**
 * Get Slot
 */
export function getSlot(slotId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Slot;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/slot/${slotId}`, {
        ...opts
    });
}
/**
 * Remove Slot
 */
export function removeSlot(slotId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SlotShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/slot/${slotId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Slot
 */
export function updateSlot(slotId: string, slotUpdate: SlotUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Slot;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/slot/${slotId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: slotUpdate
    }));
}
/**
 * Get Store Comments
 */
export function getStoreComments({ storeId }: {
    storeId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreComment[];
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_comment${QS.query(QS.form({
        store_id: storeId
    }))}`, {
        ...opts
    });
}
/**
 * Create Store Comment
 */
export function createStoreComment(storeCommentCreate: StoreCommentCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: StoreComment;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/store_comment", oazapfts.json({
        ...opts,
        method: "POST",
        body: storeCommentCreate
    }));
}
/**
 * Get Store Comment
 */
export function getStoreComment(storeCommentId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreComment;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_comment/${storeCommentId}`, {
        ...opts
    });
}
/**
 * Remove Store Comment
 */
export function removeStoreComment(storeCommentId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreCommentShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_comment/${storeCommentId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Store Comment
 */
export function updateStoreComment(storeCommentId: string, storeCommentUpdate: StoreCommentUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreComment;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_comment/${storeCommentId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: storeCommentUpdate
    }));
}
/**
 * Get Store Groups
 */
export function getStoreGroups(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreGroup[];
    }>("/store_group", {
        ...opts
    });
}
/**
 * Create Store Group
 */
export function createStoreGroup(storeGroupCreate: StoreGroupCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: StoreGroup;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/store_group", oazapfts.json({
        ...opts,
        method: "POST",
        body: storeGroupCreate
    }));
}
/**
 * Get Store Group
 */
export function getStoreGroup(storeGroupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreGroup;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_group/${storeGroupId}`, {
        ...opts
    });
}
/**
 * Remove Store Group
 */
export function removeStoreGroup(storeGroupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreGroupShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_group/${storeGroupId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Store Group
 */
export function updateStoreGroup(storeGroupId: string, storeGroupUpdate: StoreGroupUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StoreGroup;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store_group/${storeGroupId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: storeGroupUpdate
    }));
}
/**
 * Get Store
 */
export function getStore(storeId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Store;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store/${storeId}`, {
        ...opts
    });
}
/**
 * Update Store
 */
export function updateStore(storeId: string, storeUpdate: StoreUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Store;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store/${storeId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: storeUpdate
    }));
}
/**
 * Get Stores
 */
export function getStores({ hasAudio, hasVideo }: {
    hasAudio?: boolean;
    hasVideo?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Store[];
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/store${QS.query(QS.form({
        has_audio: hasAudio,
        has_video: hasVideo
    }))}`, {
        ...opts
    });
}
/**
 * Update Video Genre Weights
 */
export function updateVideoGenreWeights(body: VideoGenreWeightUpdate[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoGenre[];
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/video_genre/weights", oazapfts.json({
        ...opts,
        method: "PATCH",
        body
    }));
}
/**
 * Get Video Genres
 */
export function getVideoGenres(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoGenre[];
    }>("/video_genre", {
        ...opts
    });
}
/**
 * Create Video Genre
 */
export function createVideoGenre(videoGenreCreate: VideoGenreCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: VideoGenre;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/video_genre", oazapfts.json({
        ...opts,
        method: "POST",
        body: videoGenreCreate
    }));
}
/**
 * Get Video Genre
 */
export function getVideoGenre(videoGenreId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoGenre;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video_genre/${videoGenreId}`, {
        ...opts
    });
}
/**
 * Remove Video Genre
 */
export function removeVideoGenre(videoGenreId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoGenreShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video_genre/${videoGenreId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Video Genre
 */
export function updateVideoGenre(videoGenreId: string, videoGenreUpdate: VideoGenreUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoGenre;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video_genre/${videoGenreId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: videoGenreUpdate
    }));
}
/**
 * Upload Video File
 */
export function uploadVideoFile(bodyUploadVideoFileVideoBinaryPost: Body_upload_video_file_video_binary_post, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Video;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>("/video/binary", oazapfts.multipart({
        ...opts,
        method: "POST",
        body: bodyUploadVideoFileVideoBinaryPost
    }));
}
/**
 * Download Video File
 */
export function downloadVideoFile(sha1Hexdigest: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video/binary/${sha1Hexdigest}`, {
        ...opts
    });
}
/**
 * Get Video
 */
export function getVideo(videoId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Video;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video/${videoId}`, {
        ...opts
    });
}
/**
 * Remove Video
 */
export function removeVideo(videoId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VideoShallow;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video/${videoId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update Video
 */
export function updateVideo(videoId: string, videoUpdate: VideoUpdate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Video;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video/${videoId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: videoUpdate
    }));
}
/**
 * Update Video File
 */
export function updateVideoFile(videoId: string, bodyUpdateVideoFileVideoVideoIdBinaryPatch: Body_update_video_file_video__video_id__binary_patch, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Video;
    } | {
        status: 422;
        data: HTTPValidationError;
    }>(`/video/${videoId}/binary`, oazapfts.multipart({
        ...opts,
        method: "PATCH",
        body: bodyUpdateVideoFileVideoVideoIdBinaryPatch
    }));
}
/**
 * Get Videos
 */
export function getVideos(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Video[];
    }>("/video", {
        ...opts
    });
}
