import { FC } from 'react';
import { Divider, Layout, Menu } from 'antd';
import {
  BarChartOutlined,
  BlockOutlined,
  CloudServerOutlined,
  ControlOutlined,
  EuroCircleOutlined,
  FieldTimeOutlined,
  InteractionOutlined,
  LogoutOutlined,
  PlaySquareOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';

const SideMenu: FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Layout.Sider theme="dark" breakpoint="lg" collapsedWidth={0}>
      <div
        style={{
          color: '#fff',
          margin: '8px 24px',
          fontWeight: 600,
          fontSize: 18,
        }}
      >
        InstoreTV 2.0
      </div>
      <Divider
        style={{ marginTop: 8, marginBottom: 8, borderTopColor: '#fff' }}
      />
      <Menu mode="inline" theme="dark" selectedKeys={[location.pathname]}>
        <Menu.Item key="/video" icon={<PlaySquareOutlined />}>
          <Link to="/video">Videos</Link>
        </Menu.Item>
        <Menu.Item key="/video-genre" icon={<UnorderedListOutlined />}>
          <Link to="/video-genre">Video Genres</Link>
        </Menu.Item>
        <Menu.Item key="/video-genre-mixer" icon={<ControlOutlined />}>
          <Link to="/video-genre-mixer">Genre Mixer</Link>
        </Menu.Item>
        <Menu.Item key="/store" icon={<ShopOutlined />}>
          <Link to="/store">Stores</Link>
        </Menu.Item>
        <Menu.Item key="/store-group" icon={<BlockOutlined />}>
          <Link to="/store-group">Store Gruppen</Link>
        </Menu.Item>
        <Menu.Item key="/store-status" icon={<FieldTimeOutlined />}>
          <Link to="/store-status">Store Status</Link>
        </Menu.Item>
        <Menu.Item key="/campaign" icon={<EuroCircleOutlined />}>
          <Link to="/campaign">Kampagnen</Link>
        </Menu.Item>
        <Menu.Item key="/slot" icon={<SplitCellsOutlined />}>
          <Link to="/slot">Slots</Link>
        </Menu.Item>
        <Menu.Item key="/playlist" icon={<CloudServerOutlined />}>
          <Link to="/playlist">Playlists</Link>
        </Menu.Item>
        <Menu.Item key="/report" icon={<BarChartOutlined />}>
          <Link to="/report">Reports</Link>
        </Menu.Item>
        <Menu.Item key="/command" icon={<InteractionOutlined />}>
          <Link to="/command">Kommando</Link>
        </Menu.Item>
        <Menu.Item key="/user" icon={<UserOutlined />}>
          <Link to="/user">Benutzer</Link>
        </Menu.Item>
        <Menu.Item
          key="/logout"
          icon={<LogoutOutlined />}
          onClick={() => history.push('/auth/login')}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default SideMenu;
