import { FC } from 'react';
import { useHistory } from 'react-router';
import UserOverview from '../../components/user/UserOverview';

const UserOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <UserOverview selectUser={(userId) => history.push(`/user/${userId}`)} />
  );
};

export default UserOverviewPage;
