import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { getDmme } from '../../utilities/api';
import ITVTag from '../common/ITVTag';

type DmmeTagProps = {
  dmmeId: string;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const DmmeTag: FC<DmmeTagProps> = ({ dmmeId, color }) => {
  const { data: dmme } = useQuery(['/dmme', { id: dmmeId }], () =>
    getDmme(dmmeId).then((res) => (res.status === 200 ? res.data : undefined))
  );

  return <ITVTag color={color}>{dmme?.serial_number}</ITVTag>;
};

export default DmmeTag;
