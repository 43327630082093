import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import StoreGroupSingle from '../../components/store-group/StoreGroupSingle';

type StoreGroupSinglePageProps = {
  storeGroupId: string;
};

const StoreGroupSinglePage: FC<
  RouteConfigComponentProps<StoreGroupSinglePageProps>
> = ({ match }) => {
  const { storeGroupId } = match.params;

  return <StoreGroupSingle storeGroupId={storeGroupId} />;
};

export default StoreGroupSinglePage;
