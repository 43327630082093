import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { FC } from 'react';
import StoreTag from './StoreTag';

type StoreTagListProps = {
  storeIds: string[];
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
};

const StoreTagList: FC<StoreTagListProps> = ({ storeIds, color }) => (
  <>
    {storeIds.map((storeId) => (
      <StoreTag key={storeId} storeId={storeId} color={color} />
    ))}
  </>
);

export default StoreTagList;
