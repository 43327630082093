import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Row,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getPlaylistsByDate, PlaylistSmall } from '../../utilities/api';
import ITVTag from '../common/ITVTag';
import Time from '../common/Time';
import StoreSelect from '../store/StoreSelect';
import getBooleanSearch from '../table-search/boolean-search';

type PlaylistOverviewProps = {
  selectPlaylist?: (playlistId: string) => void;
};

const PlaylistOverview: FC<PlaylistOverviewProps> = ({ selectPlaylist }) => {
  const [date, setDate] = useState(moment());
  const dateString = date.toISOString(true).slice(0, 10);
  const [storeId, setStoreId] = useState<string>();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const offset = page * limit;

  const [hasAudio, setHasAudio] = useState<boolean>();
  const [hasVideo, setHasVideo] = useState<boolean>();
  const [orderBy, setOrderBy] = useState<
    | { dmmeCountOrderBy: 'DESC' | 'ASC' }
    | { storeCountOrderBy: 'DESC' | 'ASC' }
    | { itemCountOrderBy: 'DESC' | 'ASC' }
  >();

  const queryObject = {
    date: dateString,
    offset,
    limit,
    hasAudio,
    hasVideo,
    storeId,
    ...(orderBy ? orderBy : {}),
  };

  const { data: playlists, isLoading } = useQuery(
    ['/playlist', queryObject],
    () =>
      getPlaylistsByDate(queryObject).then((res) =>
        res.status === 200 ? res.data : undefined
      )
  );

  const columns: ColumnsType<PlaylistSmall> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Dauer',
      dataIndex: 'duration',
      render: (duration: number) => <Time milliseconds={duration} />,
    },
    {
      title: 'Datum',
      dataIndex: 'date',
    },
    {
      title: 'Programm',
      dataIndex: 'program',
      render: (program: string) => <ITVTag>{program}</ITVTag>,
    },
    {
      title: 'Video',
      dataIndex: 'has_video',
      ...getBooleanSearch<PlaylistSmall>('has_video'),
      onFilter: undefined,
      render: (hasVideo: boolean) => <Checkbox checked={hasVideo} />,
    },
    {
      title: 'Audio',
      dataIndex: 'has_audio',
      ...getBooleanSearch<PlaylistSmall>('has_audio'),
      onFilter: undefined,
      render: (hasAudio: boolean) => <Checkbox checked={hasAudio} />,
    },
    {
      title: 'Anz. DMMEs',
      dataIndex: 'dmme_count',
      sorter: true,
    },
    {
      title: 'Anz. Stores',
      dataIndex: 'store_count',
      sorter: true,
    },
    {
      title: 'Anz. Videos',
      dataIndex: 'item_count',
      sorter: true,
    },
    {
      key: 'actions',
      render: (_, playlist) => (
        <>
          <Button
            type="link"
            onClick={() => selectPlaylist && selectPlaylist(playlist.id)}
          >
            Öffnen
          </Button>
        </>
      ),
    },
  ];

  const handleFiltersChange = (filters: {
    has_video?: [boolean];
    has_audio?: [boolean];
  }) => {
    if (filters.has_audio?.length === 1) {
      setHasAudio(filters.has_audio.find(() => true));
    } else {
      setHasAudio(undefined);
    }
    if (filters.has_video?.length === 1) {
      setHasVideo(filters.has_video.find(() => true));
    } else {
      setHasVideo(undefined);
    }
  };

  const handleSorterChange = ({
    field,
    order,
  }: SorterResult<PlaylistSmall>) => {
    const orderDirection = order === 'ascend' ? 'ASC' : 'DESC';
    if (field === 'dmme_count')
      setOrderBy({ dmmeCountOrderBy: orderDirection });
    if (field === 'store_count')
      setOrderBy({ storeCountOrderBy: orderDirection });
    if (field === 'item_count')
      setOrderBy({ itemCountOrderBy: orderDirection });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card bodyStyle={{ padding: 0 }}>
          <Collapse defaultActiveKey={['default']} ghost>
            <Collapse.Panel header="Filter" key="default">
              <DatePicker
                value={date}
                onChange={(value) => value && setDate(value)}
                allowClear={false}
                style={{ marginRight: 16, marginBottom: 16 }}
              />
              <StoreSelect value={storeId} onChange={setStoreId} allowClear />
            </Collapse.Panel>
          </Collapse>
        </Card>
      </Col>
      <Col span={24}>
        <Table<PlaylistSmall>
          columns={columns}
          dataSource={playlists?.result}
          rowKey="id"
          loading={isLoading}
          size="small"
          pagination={{
            pageSizeOptions: ['100', '500', '2500'],
            current: page + 1,
            total: playlists?.total,
            pageSize: limit,
            onChange: (newPage) => setPage(newPage - 1),
            onShowSizeChange: (_, newSize) => setLimit(newSize),
          }}
          onChange={(_, filters, sorter) => {
            handleFiltersChange(filters);
            handleSorterChange(sorter as SorterResult<PlaylistSmall>);
          }}
        />
      </Col>
    </Row>
  );
};

export default PlaylistOverview;
