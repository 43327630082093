import { Alert, Button, Empty, Input, Popover, Select, Spin, Tag } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getStores, Store } from '../../utilities/api';

type StoreMultiSelectProps = {
  values?: string[];
  onChange?: (storeIds: string[]) => void;
  filter?: {
    hasAudio?: boolean;
    hasVideo?: boolean;
  };
};

const StoreMultiSelect: FC<StoreMultiSelectProps> = ({
  values,
  onChange,
  filter,
}) => {
  const [internalValues, setInternalValues] = useState<string[] | undefined>(
    values
  );
  useEffect(() => setInternalValues(values), [values]);

  const internalOnChange = (storeIds: string[]) => {
    setInternalValues(storeIds);
    onChange?.(storeIds);
  };

  const { data: stores, isLoading } = useQuery(['/store', filter], () =>
    getStores(filter).then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
  );

  const options = stores?.map((store) => {
    const {
      id: value,
      store_number: storeNumber,
      city,
      address,
      zip_code: zipCode,
      has_video: hasVideo,
    } = store;

    return {
      value,
      label: `${storeNumber} - ${city}, ${address}, ${zipCode} - ${
        hasVideo ? 'mit Video' : 'ohne Video'
      }`,
      shortLabel: `${storeNumber}, ${city}, ${address}`,
    };
  });

  return (
    <>
      <Input.Group style={{ width: '100%', display: 'flex' }} compact>
        <Select
          mode="multiple"
          style={{ flexGrow: 1 }}
          placeholder="Store auswählen"
          optionLabelProp="shortLabel"
          autoClearSearchValue={false}
          value={internalValues}
          onChange={internalOnChange}
          options={options}
          filterOption={(input, option) =>
            option?.label
              ?.toString()
              .toLowerCase()
              .includes(input.toLowerCase()) || false
          }
          notFoundContent={
            isLoading ? (
              <Spin size="small" />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
        />
        <Button>{internalValues?.length || 0} ausgewählt</Button>
      </Input.Group>
      <StoreBulkImport
        isLoading={isLoading}
        stores={stores}
        values={internalValues}
        onChange={internalOnChange}
      />
    </>
  );
};

type StoreBulkImportProps = {
  isLoading: boolean;
  stores?: Store[];
  values?: string[];
  onChange?: (storeIds: string[]) => void;
};

const StoreBulkImport: FC<StoreBulkImportProps> = ({
  isLoading,
  stores = [],
  values,
  onChange,
}) => {
  const [bulkImportValue, setBulkImportValue] = useState('');
  const splittedBulkImportValues = bulkImportValue.split(/[\s,]+/);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const storeNumberSet = useMemo(
    () => new Set(stores.map(({ store_number }) => store_number)),
    [stores]
  );

  const importableStoreNumbers = splittedBulkImportValues.filter(
    (storeNumber) => storeNumberSet.has(storeNumber)
  );
  const unimportableStoreNumbers = splittedBulkImportValues.filter(
    (storeNumber) => storeNumber && !storeNumberSet.has(storeNumber)
  );

  const importStores = () => {
    const newStoreIds = importableStoreNumbers
      .map((storeNumber) =>
        stores.find((store) => store.store_number === storeNumber)
      )
      .map((store) => store?.id)
      .filter((storeId): storeId is string => storeId !== undefined);
    const valueSet = new Set([...(values || []), ...newStoreIds]);
    onChange?.(Array.from(valueSet));
    setBulkImportValue('');
    setIsPopoverVisible(false);
  };

  return (
    <Popover
      visible={isPopoverVisible}
      onVisibleChange={(visible) => setIsPopoverVisible(visible)}
      content={() => (
        <div style={{ width: 800 }}>
          <Input.TextArea
            style={{ height: 200, marginBottom: 8 }}
            placeholder="Bsp: 01804, 10343, 09996, 50495"
            rows={10}
            value={bulkImportValue}
            onChange={(e) => setBulkImportValue(e.target.value)}
          />
          {isLoading && <Spin />}
          {!isLoading && unimportableStoreNumbers.length !== 0 && (
            <Alert
              style={{ marginBottom: 8 }}
              type="error"
              message={
                <>
                  Nicht importierbare Stores: &nbsp;
                  {unimportableStoreNumbers.map((storeNumber) => (
                    <Tag style={{ marginRight: 4 }} key={storeNumber}>
                      {storeNumber}
                    </Tag>
                  ))}
                </>
              }
            />
          )}
          {!isLoading && (
            <Button type="primary" onClick={() => importStores()}>
              {importableStoreNumbers.length} Stores importieren
            </Button>
          )}
        </div>
      )}
      title="Bulk Import"
      trigger="click"
      placement="topLeft"
    >
      <Button type="link" style={{ padding: 0 }}>
        Bulk Import
      </Button>
    </Popover>
  );
};

export default StoreMultiSelect;
