import { FC } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import UserSingle from '../../components/user/UserSingle';

type UserSinglePageProps = {
  userId: string;
};

const UserSinglePage: FC<RouteConfigComponentProps<UserSinglePageProps>> = ({
  match,
}) => {
  const { userId } = match.params;

  return <UserSingle userId={userId} />;
};

export default UserSinglePage;
