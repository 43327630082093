import { FC } from 'react';
import { useHistory } from 'react-router';
import CampaignOverview from '../../components/campaign/CampaignOverview';

const CampaignOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <CampaignOverview
      selectCampaign={(campaignId) => history.push(`/campaign/${campaignId}`)}
    />
  );
};

export default CampaignOverviewPage;
