import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FC } from 'react';
import { removeVideoGenre, VideoGenre } from '../../utilities/api';

type VideoGenreDeleteButtonProps = {
  videoGenre: VideoGenre;
  buttonType?: ButtonType;
  onDelete?: () => void;
};

const VideoGenreDeleteButton: FC<VideoGenreDeleteButtonProps> = ({
  videoGenre,
  buttonType,
  onDelete,
}) => {
  const showConfirmModal = () =>
    Modal.confirm({
      title: `${videoGenre.name} löschen?`,
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () =>
        removeVideoGenre(videoGenre.id).then((res) => {
          res.status === 200 && onDelete && onDelete();
        }),
    });

  return (
    <Button type={buttonType} onClick={() => showConfirmModal()}>
      Löschen
    </Button>
  );
};

export default VideoGenreDeleteButton;
