import { FC } from 'react';
import { useHistory } from 'react-router';
import VideoOverview from '../../components/video/VideoOverview';

const VideoOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <VideoOverview
      selectVideo={(videoId) => history.push(`/video/${videoId}`)}
    />
  );
};

export default VideoOverviewPage;
