import { RouteConfig } from 'react-router-config';
import AuthLayout from './pages/auth/AuthLayout';
import LoginPage from './pages/auth/LoginPage';
import BasicLayout from './pages/basic/BasicLayout';
import CampaignOverviewPage from './pages/basic/CampaignOverviewPage';
import CampaignSinglePage from './pages/basic/CampaignSinglePage';
import CommandPage from './pages/basic/CommandPage';
import PlaylistOverviewPage from './pages/basic/PlaylistOverviewPage';
import PlaylistSinglePage from './pages/basic/PlaylistSinglePage';
import ReportPage from './pages/basic/ReportPage';
import SlotOverviewPage from './pages/basic/SlotOverviewPage';
import SlotSinglePage from './pages/basic/SlotSinglePage';
import StoreGroupOverviewPage from './pages/basic/StoreGroupOverviewPage';
import StoreGroupSinglePage from './pages/basic/StoreGroupSinglePage';
import StoreOverviewPage from './pages/basic/StoreOverviewPage';
import StoreSinglePage from './pages/basic/StoreSinglePage';
import StoreStatusPage from './pages/basic/StoreStatusPage';
import UserOverviewPage from './pages/basic/UserOverviewPage';
import UserSinglePage from './pages/basic/UserSinglePage';
import VideoGenreMixerPage from './pages/basic/VideoGenreMixerPage';
import VideoGenreOverviewPage from './pages/basic/VideoGenreOverviewPage';
import VideoGenreSinglePage from './pages/basic/VideoGenreSinglePage';
import VideoOverviewPage from './pages/basic/VideoOverviewPage';
import VideoSinglePage from './pages/basic/VideoSinglePage';

const routes: RouteConfig[] = [
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        component: LoginPage,
      },
    ],
  },
  {
    path: '/',
    component: BasicLayout,
    routes: [
      {
        path: '/video',
        exact: true,
        component: VideoOverviewPage,
      },
      {
        path: '/video/:videoId',
        exact: true,
        component: VideoSinglePage,
      },
      {
        path: '/video-genre',
        exact: true,
        component: VideoGenreOverviewPage,
      },
      {
        path: '/video-genre/:videoGenreId',
        exact: true,
        component: VideoGenreSinglePage,
      },
      {
        path: '/video-genre-mixer',
        exact: true,
        component: VideoGenreMixerPage,
      },
      {
        path: '/store',
        exact: true,
        component: StoreOverviewPage,
      },
      {
        path: '/store/:storeId',
        exact: true,
        component: StoreSinglePage,
      },
      {
        path: '/store-group',
        exact: true,
        component: StoreGroupOverviewPage,
      },
      {
        path: '/store-group/:storeGroupId',
        exact: true,
        component: StoreGroupSinglePage,
      },
      {
        path: '/store-status',
        exact: true,
        component: StoreStatusPage,
      },
      {
        path: '/campaign',
        exact: true,
        component: CampaignOverviewPage,
      },
      {
        path: '/campaign/:campaignId',
        exact: true,
        component: CampaignSinglePage,
      },
      {
        path: '/slot',
        exact: true,
        component: SlotOverviewPage,
      },
      {
        path: '/slot/:slotId',
        exact: true,
        component: SlotSinglePage,
      },
      {
        path: '/playlist',
        exact: true,
        component: PlaylistOverviewPage,
      },
      {
        path: '/playlist/:playlistId',
        exact: true,
        component: PlaylistSinglePage,
      },
      {
        path: '/command',
        exact: true,
        component: CommandPage,
      },
      {
        path: '/user',
        exact: true,
        component: UserOverviewPage,
      },
      {
        path: '/user/:userId',
        exact: true,
        component: UserSinglePage,
      },
      {
        path: '/report',
        exact: true,
        component: ReportPage,
      },
    ],
  },
];

export default routes;
