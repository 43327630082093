import { FC } from 'react';
import { useHistory } from 'react-router';
import PlaylistOverview from '../../components/playlist/PlaylistOverview';

const PlaylistOverviewPage: FC = () => {
  const history = useHistory();

  return (
    <PlaylistOverview
      selectPlaylist={(playlistId) => history.push(`/playlist/${playlistId}`)}
    />
  );
};

export default PlaylistOverviewPage;
