import { Card, Checkbox, Collapse, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC } from 'react';
import { useQuery } from 'react-query';
import {
  getPlaylistDetailed,
  PlaylistVideo,
  PlaylistSlot,
} from '../../utilities/api';
import EntityFieldRow from '../common/EntityFieldRow';
import { interpolateWarm } from 'd3-scale-chromatic';
import VideoGenreTag from '../video-genre/VideoGenreTag';
import Time from '../common/Time';
import DmmeTagList from '../dmme/DmmeTagList';
import StoreTagList from '../store/StoreTagList';

type PlaylistSingleProps = {
  playlistId: string;
};

type EnrichedPlaylistItem = (PlaylistVideo | PlaylistSlot) & {
  startTime: number;
};

const enrichPlaylistItems = (
  playlistItems: (PlaylistVideo | PlaylistSlot)[]
): EnrichedPlaylistItem[] => {
  let currentTime = 0;
  return playlistItems.map((item) => {
    const newItem: EnrichedPlaylistItem = {
      ...item,
      startTime: currentTime,
    };
    currentTime += item.duration;
    return newItem;
  });
};

const getGenreIds = (
  playlistItems: (PlaylistVideo | PlaylistSlot)[]
): string[] => {
  const genreIds = new Set<string>();
  playlistItems.forEach((item) => {
    if (item.type === '/video' && item.genre) {
      genreIds.add(item.genre.id);
    }
  });
  return Array.from(genreIds.values());
};

const createColorMap = (values: string[]) => {
  const colorMap: { [key: string]: string } = {};
  values.forEach(
    (value, index) => (colorMap[value] = interpolateWarm(index / values.length))
  );
  return colorMap;
};

const PlaylistSingle: FC<PlaylistSingleProps> = ({ playlistId }) => {
  const { data: playlist, isLoading } = useQuery(
    ['/playlist-detailed', { id: playlistId }],
    () =>
      getPlaylistDetailed(playlistId).then((res) =>
        res.status === 200 ? res.data : undefined
      )
  );
  const enrichedPlaylistItems = enrichPlaylistItems(playlist?.items || []);
  const genreIds = getGenreIds(playlist?.items || []);
  const genreIdColors = createColorMap(genreIds);

  const columns: ColumnsType<PlaylistVideo | PlaylistSlot> = [
    {
      title: 'Startzeit',
      dataIndex: 'startTime',
      render: (startTime: number) =>
        moment({ hour: 0 })
          .add(startTime, 'milliseconds')
          .format('HH:mm:ss.SSS'),
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      render: (type: '/video' | '/slot') =>
        type === '/video' ? (
          <Tag color="blue">Video</Tag>
        ) : (
          <Tag color="magenta">Slot</Tag>
        ),
    },
    {
      title: 'Genre',
      dataIndex: 'genre',
      render: (_, item) =>
        item.type === '/video' && item.genre ? (
          <VideoGenreTag
            videoGenreId={item.genre.id}
            color={genreIdColors[item.genre.id] || 'geekblue'}
          />
        ) : null,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Dauer',
      dataIndex: 'duration',
      render: (duration: number) => <Time milliseconds={duration} />,
    },
  ];

  return (
    <>
      <Card title={playlist?.name} style={{ marginBottom: 16 }}>
        <EntityFieldRow label="Name" field={playlist?.name} />
        <EntityFieldRow label="Datum" field={playlist?.date} />
        <EntityFieldRow
          label="Programm"
          field={<Tag color="geekblue">{playlist?.program}</Tag>}
        />
        <EntityFieldRow
          label="Audio"
          field={<Checkbox checked={playlist?.has_audio} />}
        />
        <EntityFieldRow
          label="Video"
          field={<Checkbox checked={playlist?.has_video} />}
        />
        <EntityFieldRow
          label="DMMEs"
          field={
            <Collapse ghost>
              <Collapse.Panel
                style={{ padding: 0 }}
                header={playlist?.dmmes.length || 0}
                key="default"
              >
                <DmmeTagList
                  dmmeIds={playlist?.dmmes.map(({ id }) => id) || []}
                />
              </Collapse.Panel>
            </Collapse>
          }
        />
        <EntityFieldRow
          label="Stores"
          field={
            <Collapse ghost>
              <Collapse.Panel
                style={{ padding: 0 }}
                header={playlist?.stores.length || 0}
                key="default"
              >
                <StoreTagList
                  storeIds={playlist?.stores.map(({ id }) => id) || []}
                />
              </Collapse.Panel>
            </Collapse>
          }
        />
      </Card>

      <Table
        columns={columns}
        dataSource={enrichedPlaylistItems}
        rowKey="startTime"
        loading={isLoading}
        size="small"
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ['100', '500', '2500'],
        }}
      />
    </>
  );
};

export default PlaylistSingle;
