import { notification } from 'antd';
import { ApiResponse } from 'oazapfts/lib/runtime';

export const showMessageOnError = (res: ApiResponse) => {
  if (res.status >= 400) {
    notification.error({ message: 'Fehler bei der Anfrage!' });
  }
  return res;
};

export const abortOnError = (res: ApiResponse) => {
  if (res.status >= 400) {
    throw Error('Error while loading, aborting!');
  }
  return res;
};
